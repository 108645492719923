import React from 'react'
import "../footer/NewFooter.css"
import footerlogo from "../../assets/images/footerpage-images/footerlogo.svg"
import insta from "../../assets/images/footerpage-images/instagram (3) 1.svg"
import facebook from "../../assets/images/footerpage-images/facebook.svg"
import linkedin from "../../assets/images/footerpage-images/linkedin (2) 1.svg"
import ximg from "../../assets/images/footerpage-images/ximg.svg"
import { Link } from 'react-router-dom'
const NewFooter = () => {
    return (
        <>
            <div className='main-footer-container'>
                <div className='footer'>
                    <div style={{ display: "flex" }}>
                        <div className='footer-div1'>
                            <img className='footerlogoimg' src={footerlogo}></img>
                            <div className='address'>
                                <p>F-307-308,  3rd Floor, Eastern Business District, Lal Bahadur Shastri Rd, near
                                    Metro, Bhandup West, Mumbai, Maharashtra -78.
                                </p>
                                <span style={{ marginTop: "20px" }}>info@itsdigitalera.in</span>
                            </div>

                        </div>
                        <div className='footer-div2'>
                            <h2>USEFUL LINK</h2>
                            {/* <Link to='/home' style={{ textDecoration: "none" }}><h3 style={{ marginTop: "40px" }}>Home</h3></Link> */}
                            <Link to='/about' style={{ textDecoration: "none" }}><h3 style={{ marginTop: "40px" }}>About Us</h3></Link>
                            {/* <h3>Our Work</h3> */}
                            {/* <h3>Core Team</h3> */}
                            <Link to='/career' style={{ textDecoration: "none" }}><h3>Career</h3></Link>
                        </div>
                        <div className='footer-div2'>
                            <h2>SERVICES</h2>
                            <Link to="/services" style={{textDecoration:"none", }}><h3 style={{ marginTop: "40px", cursor: "pointer" }}>UI UX Designing</h3></Link>
                            <Link to="/services" style={{textDecoration:"none", }}><h3 style={{ cursor: "pointer" }}>Application Developing</h3></Link>
                            <Link to="/services" style={{textDecoration:"none", }}> <h3 style={{ cursor: "pointer" }}>Digital Marketing</h3></Link>
                            <Link to="/services" style={{textDecoration:"none", }}><h3 style={{ cursor: "pointer" }}>App Monetization</h3></Link>
                        </div>
                        <div className='footer-div4'>
                            <h2>MEDIA</h2>
                            <h3 style={{ marginTop: "40px", cursor: "default" }}>Blogs</h3>
                            <Link to='/why-ide' style={{ textDecoration: "none" }}><h3>Life at Ide</h3></Link>
                            <h3 style={{ cursor: "default" }}>Our Gallery</h3>
                            <h3 style={{ cursor: "default" }}>Upcoming Events</h3>
                        </div>
                        <div className='footer-div1'>
                            <h2 style={{ marginTop: "40px" }}>FOLLOW</h2>
                            <div style={{ display: "flex", width: "60%", justifyContent: 'space-between', marginTop: "10%" }}>
                                <a target="blank" href='https://www.instagram.com/itsdigitalera?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='><img className='socialicon' src={insta}></img></a>
                                <img className='socialicon' src={facebook}></img>
                                <a target="blank" href='https://www.linkedin.com/company/itsdigitalera-marketing-solutions/mycompany/verification/'><img className='socialicon' src={linkedin}></img></a>
                            </div>
                        </div>
                    </div>
                    <div className='copyright' style={{ marginTop: "110px" }}>
                        <span style={{ fontSize: "16px" }}>Copyright @2024 ItsDigitalEra Inc. All Rights Reserved  </span>
                    </div>
                </div>


            </div>

            {/* ===========================Footer mobile view=================== */}

            <div className='main-footer-container-mob'>
                <div className='footer'>
                    <div>
                        <div className='footer-div1'>
                            <img className='footerlogoimg' src={footerlogo}></img>
                            <div className='address'>
                                <p>F-307-308,  3rd Floor, Eastern Business District, Lal Bahadur Shastri Rd, near
                                    Metro, Bhandup West, Mumbai, Maharashtra -78.
                                </p>
                                <span style={{ marginTop: "30px", fontSize: "17px", fontWeight: "200" }}>info@itsdigitalera.in</span>
                            </div>

                        </div>
                        <div className='footer-div2'>
                            <h3 >USEFUL LINK</h3>
                            <div style={{ display: "flex", width: "100%" }}>
                                {/* <Link to='/home' style={{ textDecoration: "none" }}><h4 style={{ marginTop: "7px", width: "20%", marginLeft: "20px", fontWeight: "300" }}>HOME</h4></Link> */}
                                <Link to='/why-ide' style={{ textDecoration: "none" }}></Link><h4 style={{ marginTop: "7px", width: "100px", marginLeft: "30px", fontWeight: "300" }}>WHY IDE</h4>
                                <Link to='/career' style={{ textDecoration: "none" }}><h4 style={{ marginTop: "7px", width: "20%", marginLeft: "30px", fontWeight: "300" }}>CAREER</h4></Link>
                            </div>
                            <div style={{ display: "flex", width: "100%" }}>
                                <Link to='/services' style={{ textDecoration: "none" }}><h4 style={{ marginTop: "0px", width: "20%", marginLeft: "28px", fontWeight: "300" }}>SERVICES</h4></Link>
                                <Link to='/about' style={{ textDecoration: "none" }}><h4 style={{ marginTop: "0px", width: "100px", marginLeft: "30px", fontWeight: "300" }}>ABOUT US</h4></Link>
                            </div>

                        </div>

                        <div className='footer-div1'>
                            <h3 style={{ marginTop: "10px", marginLeft: "50px" }}>FOLLOW</h3>
                            <div style={{ display: "flex", width: "60%", marginTop: "10%" }}>
                                <a target="blank" href='https://www.instagram.com/itsdigitalera?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='><img className='socialicon' src={insta}></img></a>
                           
                                <img className='socialicon' src={facebook}></img>
                                <a target="blank" href='https://www.linkedin.com/company/itsdigitalera-marketing-solutions/mycompany/verification/'><img className='socialicon' src={linkedin}></img></a>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: "60px", width: "80%", marginLeft: "35px" }}>
                        <span style={{ fontSize: "15px" }}>Copyright @2024 ItsDigitalEra Inc. All Rights Reserved  </span>
                    </div>
                </div>
            </div>

            {/* ===========================Footer Start tab====================== */}
            <div className='main-footer-container-tab'>
                <div className='footer'>
                    <div style={{ display: "flex" }}>
                        <div className='footer-div1'>
                            <img className='footerlogoimg' src={footerlogo}></img>
                            <div className='address'>
                                <p>F308, 3rd Floor, Eastern Business District, Lal Bahadur Shastri Rd, near
                                    Metro, Bhandup West, Mumbai, Maharashtra -78.
                                </p>
                                <span style={{ marginTop: "20px" }}>info@itsdigitalera.in</span>
                            </div>

                        </div>
                        <div className='footer-div2'>
                            <h2>USEFUL LINK</h2>
                            <Link to='/home' style={{ textDecoration: "none" }}><h3 style={{ marginTop: "40px" }}>Home</h3></Link>
                            <h3>About Us</h3>
                            <h3>Our Work</h3>
                            <h3>Core Team</h3>
                            <Link to="/career"><h3>Career</h3></Link>
                        </div>
                        <div className='footer-div2'>
                            <h2>SERVICES</h2>
                            <Link to="/services"><h3 style={{ marginTop: "40px" }}>UI UX Designing</h3></Link>
                            <h3>Application Developing</h3>
                            <h3>Digital Marketing</h3>
                            <h3>App Monetization</h3>
                        </div>
                        <div className='footer-div4'>
                            <h2>MEDIA</h2>
                            <h3 style={{ marginTop: "40px" }}>Blogs</h3>
                            <h3>Life at Ide</h3>
                            <h3>Our Gallery</h3>
                            <h3>Upcoming Events</h3>
                        </div>
                        <div className='footer-div5'>
                            <h2>FOLLOW</h2>
                            <div className='socialmedia'>
                                <div style={{ display: "flex", justifyContent: "space-between",  }}>
                                    <a target="blank" href='https://www.instagram.com/itsdigitalera?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='><img className='socialicon' src={insta}></img></a>
                                  
                                    <img className='socialicon' src={facebook}></img>
                                    <a target="blank" href='https://www.linkedin.com/company/itsdigitalera-marketing-solutions/mycompany/verification/'><img className='socialicon' src={linkedin}></img></a>
                                </div>
                                

                            </div>
                        </div>
                    </div>

                    <div style={{ marginTop: "85px" }}>
                        <span style={{ fontSize: "16px" }}>Copyright @2024 ItsDigitalEra Inc. All Rights Reserved  </span>
                    </div>
                </div>
            </div>

        </>



    )
}

export default NewFooter
