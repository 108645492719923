import React from 'react'
import "./NotFound.css"
// import logo from "../../assets/images/navbar-images/navlogo.svg"
import logo from "../../assets/images/not-found-logo/logo.png"
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <div>

            <div className='Main-container'>
                <div>
                    <Link to='/'>
                        <img src={logo} style={{ height: '250px' }}>
                        </img>
                    </Link>
                </div>
                <div >
                    <div >
                        <h1 >Uh-Oh ....</h1>
                    </div>
                </div>

                <div >
                    <div >
                        <p >the page you are looking for may have been moved ,deleted,</p>
                        <p >or possibly never exists</p>
                    </div>
                </div>


                <div>
                    <h1 class="responsive-text">404.</h1>
                </div>

            </div>

        </div>
    )
}

export default NotFound
