import React, { useState, useEffect } from 'react'
import NavigationBar from "../../component/NavigationBar"
import "../career/CareerPage.css"
import performancename from "../../assets/images/career-images/performance.svg"
import Slider from "react-slick";
import bagele from "../../assets/images/elements/bag-ele.svg"
import searchele from "../../assets/images/elements/search-ele.svg"
import levelupele from "../../assets/images/elements/level-ele.svg"
import lifeatidename from "../../assets/images/career-images/life-at-ide.svg"
import line1 from "../../assets/images/career-images/Line 10.svg"
import jobopeningname from "../../assets/images/jobopening-images/jobopeningname.svg"
import { Button, Col, Form, Input, Modal, Row, Spin, Upload, message } from 'antd'
import jobopeningicon from "../../assets/images/jobopening-images/job-opening-icon.svg"
import NewFooter from '../footer/NewFooter'
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons'
import { useGetJobOpeningDataQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";
import { useGetLifeAtIdeDataQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";
import { usePostCandidateFormMutation } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi'
import { useGetEmpOfTheMonthDataQuery } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi';


const CustomLoadingAnimation = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '300px' }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            <p style={{ marginTop: '10px' }}>Loading...</p>
        </div>
    )
}


const CareerPage = () => {

    const [form] = Form.useForm();

    const [loadingAnimation, setLoadingAnimation] = useState(true)
    const [atIde, setAtIde] = useState(null)
    const [lifeAtIdeUrl, setLifeAtIdeUrl] = useState();
    const [isPlaying, setIsPlaying] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);

    };

    const [jobOpeningApiData, setJobOpeningApiData] = useState(null)
    const [uiUxDesigner, setUiUxDesigner] = useState(null)
    const [android, setAndroid] = useState(null)
    const [flutter, setFlutter] = useState(null)
    const [appTester, setAppTester] = useState(null)

    const [empOfTheMonth, setEmpOfTheMonth] = useState(null)

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [candApplicationFor, setCandApplicationFor] = useState('')
    const [candName, setCandName] = useState('')
    const [candEmail, setCandEmail] = useState('')
    const [candContact, setCandContact] = useState('')
    const [candExp, setCandExp] = useState('')
    const [candDesig, setCandDesig] = useState('')
    const [fileList, setFileList] = useState([])
    const [playVideo, setPlayVideo] = useState(1)


    // ======================= Get Job Data With API Call Start Here 

    const { data: jobOpeningdata, isLoading: isLoadingJobOpeningdata, isSuccess: isSuccessJobOpeningdata } = useGetJobOpeningDataQuery()
    // ======================= Get Emp of the Month With API Call Start Here 
    const { data: empOfTheMonthData, isLoading: isLoadingEmpOfTheMonthData, isSuccess: isSuccessEmpOfTheMonthData, refetch: refetchEmpOfTheMonthData } = useGetEmpOfTheMonthDataQuery()

    useEffect(() => {
        handleToFetchJobData()

    }, [jobOpeningdata, isSuccessJobOpeningdata, empOfTheMonthData, isSuccessEmpOfTheMonthData])

    const handleToFetchJobData = () => {
        if (jobOpeningdata && isSuccessJobOpeningdata && empOfTheMonthData && isSuccessEmpOfTheMonthData) {

            setJobOpeningApiData(jobOpeningdata)
            setUiUxDesigner(jobOpeningdata[0])
            setAndroid(jobOpeningdata[1])
            setFlutter(jobOpeningdata[2])
            setAppTester(jobOpeningdata[3])

            setEmpOfTheMonth(empOfTheMonthData)

            setLoadingAnimation(true)
            setTimeout(() => {
                setLoadingAnimation(false)
            }, 100)

        }

    }



    // API Call for form 
    const [createPost, { isLoading, isError }] = usePostCandidateFormMutation();

    const handleModalOpen = (id, application_for) => {

        setCandApplicationFor(application_for)

        showModal(true)
    }


    const handleCandidateForm = async () => {


        if (!candName || !candEmail || !candContact || !candExp || !candDesig || !fileList[0]) {

            if (!candName) {
                message.warning('Candidate name required .');
                return false
            }
            if (!candEmail) {
                message.warning('Candidate email required .');
                return false
            }
            if (!candContact) {
                message.warning('Candidate contact required .');
                return false
            }
            if (!candExp) {
                message.warning('Candidate experience required .');
                return false
            }
            if (!candDesig) {
                message.warning('Candidate designation required .');
                return false
            }
            if (!fileList[0]) {
                message.warning('Candidate resume required .');
                return false
            }
        } else {
            try {
                // Calling the `mutate` function with the post data
                let formData = new FormData();
                formData.append('application_for', candApplicationFor)
                formData.append('cand_name', candName)
                formData.append('cand_email', candEmail)
                formData.append('cand_contact', candContact)
                formData.append('cand_exp', candExp)
                formData.append('cand_desig', candDesig)

                // Append each file individually
                fileList.forEach((file, index) => {
                    formData.append('cand_resume', file.originFileObj);
                });

                const res = await createPost(formData);


                // Reset form fields after successful submission

                form.resetFields();
                resetForm()
                setFileList([])


                // setJobopeningFormModal(false)
                setIsModalOpen(false);
                // The post request was successful
                message.success('Successfully Submited');
            } catch (error) {
                // Handle errors here
                console.error('Error creating post:', error);
                message.error('Check your form and submit .');
            }

        }

    }
    const resetForm = () => {
        setCandApplicationFor('')
        setCandName('');
        setCandEmail('');
        setCandContact('');
        setCandExp('');
        setCandDesig('');
        setFileList([]);
    }

    // ======================= Get Job Data With API Call End Here  


    // ======================= Get LifeAt IDE With API Call Start Here  
    const { data: atIdeData, isLoading: isLoadingAtIdeData, isSuccess: isSuccessAtIdeData } = useGetLifeAtIdeDataQuery()

    const isDataLoaded = isSuccessAtIdeData;


    useEffect(() => {
        fetchData()

    }, [atIdeData, isSuccessAtIdeData]);

    const fetchData = () => {
        if (atIdeData && isSuccessAtIdeData) {
            setAtIde(atIdeData); // Update atIde state with data

            // Set Default Video
            setLifeAtIdeUrl(atIdeData[0].at_ide_video)



        }
    }






    // useEffect for video events
    useEffect(() => {
        const video = document.getElementById("myVideo");

        if (lifeAtIdeUrl && video) {
            video.addEventListener("play", () => {
                setIsPlaying(true);
            });

            video.addEventListener("pause", () => {
                setIsPlaying(false);
                setButtonDisabled(false); // Enable the button when video is paused
            });

            return () => {
                video.removeEventListener("play", () => { });
                video.removeEventListener("pause", () => { });
            };
        }
    }, [lifeAtIdeUrl]); // Include the necessary dependency

    // Check if lifeAtIde is null or undefined
    if (!atIde) {
        return
        // <p>Data not available.</p>;
    }

    // Filter data if lifeAtIde is not null or undefined
    const filteredData = atIde.filter((item) => item.video_id !== '0');




    // ======================= Get LifeAt IDE With API Call End Here  




    // const settings = {
    //     dots: false,
    //     infinite: true,
    //     centerMode: true,
    //     speed: 1500,
    //     slidesToShow: 4,
    //     slidesToScroll: true,
    //     swipeToSlide: true,
    //     initialSlide: 0,
    //     centerPadding: '30px',
    //     draggable: true,
    //     arrows: false,
    //     marginLeft: '10px',
    //     responsive: [
    //         {
    //             breakpoint: 1500,
    //             settings: {
    //                 slidesToShow: 3,
    //                 slidesToScroll: true,
    //                 swipeToSlide: true,
    //                 infinite: true,
    //                 draggable: true,
    //                 dots: false,
    //                 marginLeft: '20px',
    //             }
    //         },
    //         {
    //             breakpoint: 1200,
    //             settings: {
    //                 slidesToShow: 3,
    //                 slidesToScroll: true,
    //                 swipeToSlide: true,
    //                 infinite: true,
    //                 draggable: true,
    //                 dots: false,
    //                 marginLeft: '20px',
    //             }
    //         },
    //         {
    //             breakpoint: 991,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: true,
    //                 swipeToSlide: true,
    //                 infinite: true,
    //                 draggable: true,
    //                 dots: false,
    //                 marginLeft: '20px',
    //             }
    //         },
    //         {
    //             breakpoint: 769,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: true,
    //                 swipeToSlide: true,
    //                 draggable: true,
    //                 initialSlide: 2
    //             }
    //         },
    //         {
    //             breakpoint: 450,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: true,
    //                 swipeToSlide: true,
    //                 draggable: true,
    //                 slidesToScroll: 1,
    //                 marginLeft: '20px',
    //             }
    //         },

    //     ]
    // };


    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: true,
        initialSlide: 0,
        draggable: true,
        arrows: false,
        swipeToSlide: true,
        marginLeft: '20px',
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: true,
                    infinite: true,
                    draggable: true,
                    swipeToSlide: true,
                    dots: false,
                    marginLeft: '20px',
                }
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: true,
                    infinite: true,
                    draggable: true,
                    swipeToSlide: true,
                    dots: false,
                    marginLeft: '20px',
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: true,
                    infinite: true,
                    draggable: true,
                    swipeToSlide: true,
                    dots: false,
                    marginLeft: '20px',
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: true,
                    infinite: true,
                    draggable: true,
                    dots: false,
                    marginLeft: '20px',
                    swipeToSlide: true,

                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: true,
                    draggable: true,
                    initialSlide: 2,
                    swipeToSlide: true,

                }
            },
            {
                breakpoint: 630,
                settings: {
                    slidesToShow: 1,
                    draggable: true,
                    slidesToScroll: true,
                    marginLeft: '20px',
                    swipeToSlide: true,

                }
            },

        ]
    };

    const jobsettings = {
        dots: false,
        infinite: true,
        centerMode: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        initialSlide: 0,
        draggable: true,
        arrows: false,
        swipe: true,
        marginLeft: '20px',
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    centerMode: false,
                    infinite: true,
                    draggable: true,
                    dots: false,
                    marginLeft: '20px',
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: false,
                    draggable: true,
                    dots: false,
                    marginLeft: '20px',
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                    infinite: true,
                    draggable: true,
                    dots: false,
                    marginLeft: '20px',
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    centerMode: false,
                    slidesToScroll: 1,
                    draggable: true,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 630,
                settings: {
                    slidesToShow: 1,
                    draggable: true,
                    slidesToScroll: 1,
                    marginLeft: '50px',
                }
            },

        ]
    };





    return (
        <>
            {
                loadingAnimation ?
                    (
                        <CustomLoadingAnimation />
                    )
                    :
                    (
                        <div>
                            <div style={{ position: "fixed", zIndex: 999, marginTop: "-5px" }}>
                                <NavigationBar />
                            </div>
                            <div className='performance-container'>
                                <div className='perform-name'>
                                    <div className='nameimg'>
                                        <img src={performancename}></img>
                                    </div>
                                    <div className='perform-ele-levelup'>
                                        <img className='elements' src={levelupele}></img>
                                    </div>
                                </div>
                                {/* ================main container start ====================== */}
                                <div className='Performance-slider'>
                                    <Slider {...settings}>
                                        {empOfTheMonth && (
                                            empOfTheMonth.map((slide, index) => (
                                                <div key={index} className='slide-career'>
                                                    {slide && (
                                                        <div className='slide-image-career'>
                                                            <img src={slide.emp_image}></img>
                                                            <h2>Employee Of the Month</h2>
                                                        </div>
                                                    )}
                                                </div>
                                            ))
                                        )}
                                    </Slider>
                                </div>

                               
                                {/* ===================life at ide container start ================== */}


                                <div className='life-at-ide-container'>
                                    <img src={lifeatidename}></img>

                                    <div className='perform-ele-search'>
                                        <img className='elements' src={searchele}></img>
                                    </div>


                                    {
                                        atIde && (
                                            <div className='life-at-main-div'>
                                                <div className='life-text-div'>
                                                    <div className='row-1'>

                                                        <div className='row-content1' >

                                                            <div className='inner-content1' style={{ display: "flex", cursor: 'pointer' }}>
                                                                <img src={line1}></img>
                                                                <h2 onMouseEnter={() => {
                                                                    setLifeAtIdeUrl(atIde[1].at_ide_video);
                                                                }}>{atIde[1].video_title}</h2>
                                                            </div>
                                                            <div className='innter-text'>
                                                                <p>{atIde[1].video_desc}</p>
                                                            </div>
                                                        </div>

                                                        <div div className='row-content2' >
                                                            <div className='inner-content2' style={{ display: "flex", cursor: 'pointer' }}>
                                                                <img src={line1}></img>
                                                                <h2 onMouseEnter={() => {
                                                                    setLifeAtIdeUrl(atIde[2].at_ide_video);
                                                                }}>{atIde[2].video_title}</h2>
                                                            </div>
                                                            <div className='innter-text'>
                                                                <p>{atIde[2].video_desc}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row-2'>
                                                        <div className='row-content1'>
                                                            <div className='inner-content1' style={{ display: "flex", cursor: 'pointer' }}>
                                                                <img src={line1}></img>
                                                                <h2 onMouseEnter={() => {
                                                                    setLifeAtIdeUrl(atIde[3].at_ide_video);
                                                                }}>{atIde[3].video_title}</h2>
                                                            </div>
                                                            <div className='innter-text'>
                                                                <p>{atIde[3].video_desc}</p>
                                                            </div>
                                                        </div>
                                                        <div className='row-content2'>
                                                            <div className='inner-content2' style={{ display: "flex", cursor: 'pointer' }}>
                                                                <img src={line1}></img>
                                                                <h2 onMouseEnter={() => {
                                                                    setLifeAtIdeUrl(atIde[4].at_ide_video);
                                                                }}>{atIde[4].video_title}</h2>
                                                            </div>
                                                            <div className='innter-text'>
                                                                <p>{atIde[4].video_desc}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row-3'>
                                                        <div className='row-content1'>
                                                            <div className='inner-content1' style={{ display: "flex", cursor: 'pointer' }}>
                                                                <img src={line1}></img>
                                                                <h2 onMouseEnter={() => {
                                                                    setLifeAtIdeUrl(atIde[5].at_ide_video);
                                                                }}>{atIde[5].video_title}</h2>
                                                            </div>
                                                            <div className='innter-text'>
                                                                <p>{atIde[5].video_desc}</p>
                                                            </div>
                                                        </div>
                                                        <div className='row-content2'>
                                                            <div className='inner-content2' style={{ display: "flex", cursor: 'pointer' }}>
                                                                <img src={line1}></img>
                                                                <h2 onMouseEnter={() => {
                                                                    setLifeAtIdeUrl(atIde[6].at_ide_video);
                                                                }}>{atIde[6].video_title}</h2>
                                                            </div>
                                                            <div className='innter-text'>
                                                                <p>{atIde[6].video_desc}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='life-img-div'>
                                                    <div className='image'>
                                                        {/* <img src={lifeideimg}></img> */}
                                                        <video id="myVideo" style={{ width: '100%', objectFit: 'cover', backgroundColor: "black" }} src={lifeAtIdeUrl} autoPlay loop muted controls={window.innerWidth <= 768} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>

                                {/* =================== life at ide mobile container start===================== */}
                                {
                                    atIde && (
                                        <div className='life-at-ide-container-mob'>

                                            <img style={{ height: "50px", marginTop: "70px" }} src={lifeatidename}></img>

                                            <div className='ide-life-main-div'>
                                                <div className='ide-life-image'>
                                                    <video src={atIde[1].at_ide_video} controls ></video>
                                                </div>
                                                <div className='row-content1'>
                                                    <div className='inner-content1' style={{ display: "flex" }}>
                                                        <img src={line1}></img>
                                                        <h2>{atIde[1].video_title}</h2>
                                                    </div>
                                                    <div className='innter-text'>
                                                        <p>{atIde[1].video_desc}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='ide-life-main-div1'>
                                                <div className='ide-life-image'>
                                                    <video src={atIde[2].at_ide_video} controls ></video>
                                                </div>
                                                <div className='row-content1'>
                                                    <div className='inner-content1' style={{ display: "flex" }}>
                                                        <img src={line1}></img>
                                                        <h2>{atIde[2].video_title}</h2>
                                                    </div>
                                                    <div className='innter-text'>
                                                        <p>{atIde[2].video_desc}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='ide-life-main-div1'>
                                                <div className='ide-life-image'>
                                                    <video src={atIde[3].at_ide_video} controls ></video>
                                                </div>
                                                <div className='row-content1'>
                                                    <div className='inner-content1' style={{ display: "flex" }}>
                                                        <img src={line1}></img>
                                                        <h2>{atIde[3].video_title}</h2>
                                                    </div>
                                                    <div className='innter-text'>
                                                        <p>{atIde[3].video_desc}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='ide-life-main-div1'>
                                                <div className='ide-life-image'>
                                                    <video src={atIde[4].at_ide_video} controls ></video>
                                                </div>
                                                <div className='row-content1'>
                                                    <div className='inner-content1' style={{ display: "flex" }}>
                                                        <img src={line1}></img>
                                                        <h2>{atIde[4].video_title}</h2>
                                                    </div>
                                                    <div className='innter-text'>
                                                        <p>{atIde[4].video_desc}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='ide-life-main-div1'>
                                                <div className='ide-life-image'>
                                                    <video src={atIde[5].at_ide_video} controls ></video>
                                                </div>
                                                <div className='row-content1'>
                                                    <div className='inner-content1' style={{ display: "flex" }}>
                                                        <img src={line1}></img>
                                                        <h2>{atIde[5].video_title}</h2>
                                                    </div>
                                                    <div className='innter-text'>
                                                        <p>{atIde[5].video_desc}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='ide-life-main-div1'>
                                                <div className='ide-life-image'>
                                                    <video src={atIde[6].at_ide_video} controls ></video>
                                                </div>
                                                <div className='row-content1'>
                                                    <div className='inner-content1' style={{ display: "flex" }}>
                                                        <img src={line1}></img>
                                                        <h2>{atIde[6].video_title}</h2>
                                                    </div>
                                                    <div className='innter-text'>
                                                        <p>{atIde[6].video_desc}</p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    )
                                }

                                {/* ===========================Job opening Start========================= */}
                                <div className='main-jobopening-container'>
                                    <div style={{ marginTop: "2%" }}>
                                        <img src={jobopeningname}></img>
                                    </div>
                                    <div className='elements' style={{ textAlign: "start", marginLeft: "15%", marginTop: "-2%" }}>
                                        <img src={bagele}></img>
                                    </div>

                                    <div className='job-row'>
                                        <div className='job-opening-row'>


                                            <Slider  {...jobsettings}>
                                                {
                                                    jobOpeningApiData && (
                                                        jobOpeningApiData.map((slide, index) => (
                                                            <div key={index} className='jobopening-col' style={{ marginLeft: "30px" }}>

                                                                <img src={jobopeningicon} alt="" />

                                                                <h2 style={{ fontSize: "20px" }}>{slide.application_domain}</h2>
                                                                <h3 style={{ lineHeight: "25px" }}>{slide.job_time} | {slide.job_exp}</h3>
                                                                <h3>Job Vacancy : {slide.job_vacancy}</h3>
                                                                <div className='submit-btn'>
                                                                    {slide.job_vacancy === "0" ? (
                                                                        <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                                    ) :
                                                                        (
                                                                            <Button className='submit'
                                                                                onClick={() => handleModalOpen(1, slide.application_domain)}
                                                                            >Apply Now</Button>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        )))}
                                            </Slider>

                                            {/* <Col className='jobopening-col'>
                                                    <img src={jobopeningicon} alt="" />
                                                    <h2 style={{ fontSize: "20px" }}>{android.application_domain}</h2>
                                                    <h3 style={{ lineHeight: "25px" }}>{android.job_time} | {android.job_exp}</h3>
                                                    <h3>Job Vacancy : {android.job_vacancy}</h3>
                                                    <div className='submit-btn'>
                                                        {android.job_vacancy === "0" ? (
                                                            <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                        ) :
                                                            (
                                                                <Button className='submit'
                                                                    onClick={() => handleModalOpen(2, android.application_domain)}
                                                                >Apply Now</Button>
                                                            )
                                                        }
                                                    </div>
                                                </Col>
                                                <Col className='jobopening-col'>
                                                    <img src={jobopeningicon} alt="" />
                                                    <h2 style={{ fontSize: "20px" }}>{flutter.application_domain}</h2>
                                                    <h3 style={{ lineHeight: "25px" }}>{flutter.job_time} | {flutter.job_exp}</h3>
                                                    <h3>Job Vacancy : {flutter.job_vacancy}</h3>
                                                    <div className='submit-btn'>
                                                        {flutter.job_vacancy === "0" ? (
                                                            <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                        ) :
                                                            (
                                                                <Button className='submit'
                                                                    onClick={() => handleModalOpen(3, flutter.application_domain)}
                                                                >Apply Now</Button>
                                                            )
                                                        }
                                                    </div>
                                                </Col>
                                                <Col className='jobopening-col'>
                                                    <img src={jobopeningicon} alt="" />
                                                    <h2 style={{ fontSize: "20px" }}>{appTester.application_domain}</h2>
                                                    <h3 style={{ lineHeight: "25px" }}>{appTester.job_time} | {appTester.job_exp}</h3>
                                                    <h3>Job Vacancy : {appTester.job_vacancy}</h3>
                                                    <div className='submit-btn'>
                                                        {appTester.job_vacancy === "0" ? (
                                                            <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                        ) :
                                                            (
                                                                <Button className='submit'
                                                                    onClick={() => handleModalOpen(4, appTester.application_domain)}
                                                                >Apply Now</Button>
                                                            )
                                                        }
                                                    </div>
                                                </Col> */}
                                        </div>
                                    </div>
                                </div>


                                {/* ============================Job Opening start Mobile view================ */}

                                <div className='main-jobopening-container-mobile'>
                                    <div style={{ marginTop: "2%" }}>
                                        <img style={{ height: "50px" }} src={jobopeningname}></img>
                                    </div>

                                    <div>
                                        <div className='job-row'>

                                            <div className="center-slider">

                                                <Slider {...jobsettings}>
                                                    {
                                                        jobOpeningApiData && (
                                                            jobOpeningApiData.map((slide, index) => (
                                                                <div key={index} className='slide-job'>
                                                                    {slide && (
                                                                        <div className='jobopening-col' style={{ marginLeft: "40px" }}>
                                                                            {/* <div className='jobopeningdiv'> */}
                                                                            <img src={jobopeningicon} alt="" />

                                                                            <h2>{slide.application_domain}</h2>
                                                                            <h3 style={{ lineHeight: "20px" }}>{slide.job_time} | {slide.job_exp}</h3>
                                                                            <h3>Job Vacancy : {slide.job_vacancy}</h3>
                                                                            <div className='submit-btn'>
                                                                                {slide.job_vacancy === "0" ? (
                                                                                    <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                                                ) :
                                                                                    (
                                                                                        <Button className='submit'
                                                                                            onClick={() => handleModalOpen(1, slide.application_domain)}
                                                                                        >Apply Now</Button>
                                                                                    )
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )))}
                                                    {/* <div className='slide-job'>
                                                            <div className='jobopening-col' style={{ marginLeft: "40px" }}>
                                                                <img src={jobopeningicon} alt="" />
                                                                <h2>{android.application_domain}</h2>
                                                                <h3 style={{ lineHeight: "20px" }}>{android.job_time} | {android.job_exp}</h3>
                                                                <h3>Job Vacancy : {android.job_vacancy}</h3>
                                                                <div className='submit-btn'>
                                                                    {android.job_vacancy === "0" ? (
                                                                        <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                                    ) :
                                                                        (
                                                                            <Button className='submit'
                                                                                onClick={() => handleModalOpen(2, android.application_domain)}
                                                                            >Apply Now</Button>
                                                                        )
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='slide-job'>
                                                            <div className='jobopening-col' style={{ marginLeft: "40px" }}>
                                                                <img src={jobopeningicon} alt="" />
                                                                <h2>{flutter.application_domain}</h2>
                                                                <h3 style={{ lineHeight: "20px" }}>{flutter.job_time} | {flutter.job_exp}</h3>
                                                                <h3>Job Vacancy : {flutter.job_vacancy}</h3>
                                                                <div className='submit-btn'>
                                                                    {flutter.job_vacancy === "0" ? (
                                                                        <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                                    ) :
                                                                        (
                                                                            <Button className='submit'
                                                                                onClick={() => handleModalOpen(3, flutter.application_domain)}
                                                                            >Apply Now</Button>
                                                                        )
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='slide-job'>
                                                            <div className='jobopening-col' style={{ marginLeft: "40px" }}>
                                                                <img src={jobopeningicon} alt="" />
                                                                <h2>{appTester.application_domain}</h2>
                                                                <h3 style={{ lineHeight: "20px" }}>{appTester.job_time} | {appTester.job_exp}</h3>
                                                                <h3>Job Vacancy : {appTester.job_vacancy}</h3>
                                                                <div className='submit-btn'>
                                                                    {appTester.job_vacancy === "0" ? (
                                                                        <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                                    ) :
                                                                        (
                                                                            <Button className='submit'
                                                                                onClick={() => handleModalOpen(4, appTester.application_domain)}
                                                                            >Apply Now</Button>
                                                                        )
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div> */}

                                                </Slider>
                                            </div>
                                        </div>

                                    </div>

                                </div>


                                <NewFooter />

                                {/* ====================================end====================================== */}


                                {/* Modal Form  */}
                                <Modal
                                    title="Application Form"
                                    footer={null}
                                    open={isModalOpen}
                                    onCancel={handleCancel}
                                >

                                    <Form

                                        className='candidateForm' id='candidateForm' onFinish={handleCandidateForm} form={form}
                                    >

                                        {/* name */}
                                        <Form.Item
                                            name="name"
                                            rules={[
                                                { required: true, message: 'Please enter a name' },
                                                { min: 3, message: 'Name must be at least 3 characters' },
                                            ]}
                                            style={{ margin: '20px 5px' }}

                                        >
                                            <Input placeholder="Enter Your Name*"
                                                onChange={(e) => { setCandName(e.target.value) }}
                                            />
                                        </Form.Item>

                                        {/* Email  */}
                                        <Form.Item

                                            rules={[{ required: true, type: 'email', message: 'Please valid E-mail!' }]}
                                            style={{ margin: '20px 5px' }}
                                            name={"email"}
                                        >
                                            <Input
                                                placeholder="Enter Your Email*"
                                                onChange={(e) => { setCandEmail(e.target.value) }}
                                            />
                                        </Form.Item>

                                        {/* contact */}
                                        <Form.Item
                                            name="contact number"
                                            rules={[
                                                { required: true, message: 'Please enter a Contact Number' },
                                                { min: 10, message: 'Experience must be at least 10 digits' },
                                            ]}
                                            style={{ margin: '20px 5px' }}
                                        >
                                            <Input placeholder="Enter Your Contact Number*"
                                                onChange={(e) => { setCandContact(e.target.value) }}
                                            />
                                        </Form.Item>

                                        {/* Experience  */}
                                        <Form.Item
                                            name="experience"
                                            rules={[
                                                { required: true, message: 'Please enter a Experience' },
                                                { message: 'Experience required' },
                                            ]}
                                            style={{ margin: '20px 5px' }}
                                        >
                                            <Input placeholder="Enter Your Experience*"
                                                onChange={(e) => { setCandExp(e.target.value) }}
                                            />
                                        </Form.Item>


                                        {/* Designation  */}
                                        <Form.Item
                                            name="Designation"
                                            rules={[
                                                { required: true, message: 'Please enter a Designation' },
                                                { message: 'Designation required' },
                                            ]}
                                            style={{ margin: '20px 5px' }}
                                        >
                                            <Input placeholder="Enter Your Designation*"
                                                onChange={(e) => { setCandDesig(e.target.value) }}
                                            />
                                        </Form.Item>

                                        <div style={{ marginBottom: '30px', height: "111px", width: "95%", marginLeft: "13px", marginTop: "20px" }}>


                                            <Upload.Dragger
                                                name="file"
                                                multiple={false}
                                                fileList={fileList}
                                                onChange={({ fileList }) => setFileList(fileList)}
                                                onRemove={(file) => {
                                                    const newFileList = fileList.filter((item) => item.uid !== file.uid);
                                                    setFileList(newFileList);
                                                }}
                                                accept='application/pdf'


                                            >
                                                <div style={{ display: "flex", marginTop: "10px" }}>
                                                    <p className="ant-upload-drag-icon" >
                                                        < InboxOutlined />
                                                    </p>
                                                    <p style={{ marginTop: "10px" }} className="ant-upload-text">Click or drag resume to this area to upload</p>

                                                </div>

                                            </Upload.Dragger>

                                        </div>
                                        <div style={{ textAlign: "center" }}>

                                            <button
                                                style={

                                                    { width: "40%", height: "45px", margin: "0px auto", padding: "0px", borderRadius: "8px", marginTop: "10px", borderColor: "gray", backgroundColor: "#0090E0", color: "white", fontSize: 18, cursor: "pointer", textAlign: "center" }
                                                }
                                                className='btn-pill'
                                                type="submit"

                                            >
                                                {
                                                    isLoading ?
                                                        <p>Submitting</p>
                                                        :

                                                        <p style={{ marginTop: "10px" }}>Apply Now</p>
                                                }
                                            </button>
                                        </div>

                                    </Form>


                                </Modal>
                            </div>
                        </div >
                    )}
        </>
    )
}

export default CareerPage
