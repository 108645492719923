import React, { useEffect } from 'react'
import "../component/NavigationBar.css"
import logo from "../assets/images/navbar-images/navlogo.svg"
import menu from "../assets/images/navbar-images/menu.svg"
import { Button } from 'antd';
import { useState } from "react"
import closeicon from "../../src/assets/images/navbar-images/close-icon.svg"
import menulogo from "../../src/assets/images/footerpage-images/footerlogo.svg"
import Home from '../pages/home/Home';
import { Link, useLocation } from 'react-router-dom';
import About from '../pages/about/About';

// let pageId = 1;
const NavigationBar = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isActiveDot, setIsActiveDot] = useState(false);
    const [pageId, setPageId] = useState(1)
    const location = useLocation();


   const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);

    };


    const closeMenu = () => {
        setMenuOpen(false);
    };
    const activeDot = (id) => {
       
        // setPageId(id)
        pageId = id
    }

    useEffect(()=>{
        const path = location.pathname;

        switch(path){
            case "/" : 
                setPageId(1);
                break;
            case "/about" :
                setPageId(2);
                break;
            case "/services" :
                setPageId(3);
                break;
            case "/why-ide" :
                setPageId(4);
                break;
            case "/career" :
                setPageId(0);
                break;
        }
    })

    
    return (
        <div>

            <div className='navbar-page-container'>
                <div className='navbar-main'>
                    <div className='menu-btn' >
                        <img src={menu} onClick={toggleMenu}></img>
                    </div>
                    <div className='nav-logo'>
                        <Link to="/"><img src={logo}></img></Link>
                    </div>
                    <div className='nav-heading'>
                        <div className='nav-content'>
                            <div>
                                <Link to="/"  className='spannavtext'><span  style={{ color: "black", textDecorationLine: "none" }} >Home</span></Link>
                                {pageId === 1 && <div id='activedot'></div>} 
                            </div>
                            <div>
                                <Link to="/about" className='spannavtext'><span style={{ color: "black", textDecorationLine: "none" }}>About us</span></Link>
                               
                                {pageId === 2 && <div id='activedot'></div>}
                            </div>

                            <div>
                                <Link to="/services" className='spannavtext'><span style={{ color: "black", textDecorationLine: "none" }}>Services</span></Link>
                                {pageId === 3 && <div id='activedot'></div>}
                            </div>

                            <div>
                                <Link to='/why-ide' className='spannavtext'><span style={{ color: "black", textDecorationLine: "none" }}>Why Ide</span></Link>
                                {pageId === 4 && <div id='activedot'></div>}
                            </div>
                        </div>
                        {/* <div className='nav-content'>
                            <div onClick={() => activeDot(1)}>
                                <Link to="/"  className='spannavtext'><span  style={{ color: "black", textDecorationLine: "none" }} >Home</span></Link>
                                {pageId === 1 && <div id='activedot'></div>} 
                            </div>
                            <div onClick={() => activeDot(2)} >
                                <Link to="/about" className='spannavtext'><span style={{ color: "black", textDecorationLine: "none" }}>About us</span></Link>
                               
                                {pageId === 2 && <div id='activedot'></div>}
                            </div>

                            <div onClick={() => activeDot(3)} >
                                <Link to="/services" className='spannavtext'><span style={{ color: "black", textDecorationLine: "none" }}>Services</span></Link>
                                {pageId === 3 && <div id='activedot'></div>}
                            </div>

                            <div onClick={() => activeDot(4)} >
                                <Link to='/why-ide' className='spannavtext'><span style={{ color: "black", textDecorationLine: "none" }}>Why Ide</span></Link>
                                {pageId === 4 && <div id='activedot'></div>}
                            </div>
                        </div> */}
                        <div className='career-btn'>
                            {/* <btn className='btn'>Career</btn> */}
                            {/* <span>Career</span> */}
                            <Link to='/career' className='spannavtext'><Button className='btn'>Career</Button></Link>
                        </div>
                    </div>
                </div>

                {isMenuOpen && (
                    <div id='menu' className='menu-item-section'>
                        <div className='menu-item-section-innerdiv'>
                            <div style={{ textAlign: "end" }}>
                                <img style={{ height: "30px", marginTop: "45px", padding: "20px" }} src={closeicon} className='close-button' onClick={closeMenu}></img>
                            </div>
                            <div className='nav1'>
                                <img style={{ height: "40px", padding: "0px" }} src={menulogo}></img>
                            </div>

                            <div className='menu-items' style={{ marginTop: '30px' }}>
                                <Link to="/" className='spannavtext'><h3 >HOME</h3></Link>
                                <Link to="/about" className='spannavtext'><h3>ABOUT US</h3></Link>
                                <Link to="/services" className='spannavtext'><h3>SERVICES</h3></Link>
                                <Link to='/why-ide' className='spannavtext'><h3>WHY IDE</h3></Link>
                                <Link to='/career' className='spannavtext'><h3>CAREER</h3></Link>
                            </div>
                        </div>


                    </div>
                )}
                <div>

                </div>
            </div>

        </div>
    )
}

export default NavigationBar
