import logo from './logo.svg';
import './App.css';
import MainPage from './mainpage/MainPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './pages/about/About';
import Home from './pages/home/Home';
import Service from './pages/services/Service';
import WhyIDE from './pages/whyide/WhyIDE';
import CareerPage from './pages/career/CareerPage';
import NotFound from './pages/not-found/NotFound';
function App() {
  return (
    <div className="App">
      {/* <MainPage /> */}
      {/* <BrowserRouter >
        <Routes>
          <Route path='/' element={<Home pageId={1}/>}/>
          <Route path='/about' element={<About pageId={2}/>}/>
          <Route path='/services' element={<Service pageId={3}/>}/>
          <Route path='/why-ide' element={<WhyIDE pageId={4}/>}/>
          <Route path='/career' element={<CareerPage />}/>
        </Routes>
      </BrowserRouter> */}

      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home pageId={1} />} />
          <Route path='/about' element={<About pageId={2} />} />
          <Route path='/services' element={<Service pageId={3} />} />
          <Route path='/why-ide' element={<WhyIDE pageId={4} />} />
          <Route path='/career' element={<CareerPage />} />
          {/* 404 Not Found route */}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
