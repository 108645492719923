import React, { useState, useEffect } from 'react'
import "../whyide/WhyIDE.css"
import NavigationBar from "../../component/NavigationBar"
import bgimg from "../../assets/images/why-ide-images/planebg.svg"
import gpartner from "../../assets/images/whychooseus-images/googlepartner.svg"
import ads from "../../assets/images/whychooseus-images/ads.svg"
import payment from "../../assets/images/whychooseus-images/payment.svg"
import consultation from "../../assets/images/whychooseus-images/consultation.svg"
import investment from "../../assets/images/whychooseus-images/return.svg"
import record from "../../assets/images/whychooseus-images/record.svg"
import CountUp from 'react-countup';
import blurimage from "../../assets/images/homepage-images/blureffect.svg"
import bagele from "../../assets/images/elements/bag-ele.svg"
import searchele from "../../assets/images/elements/search-ele.svg"
import centerline from "../../assets/images/why-ide-images/linewithbranch.svg"
import publishertag from "../../assets/images/why-ide-images/publisher.svg"
import advertizertag from "../../assets/images/why-ide-images/advertizer.svg"
import publishercontent1 from "../../assets/images/why-ide-images/content1publisher.svg"
import publishercontent2 from "../../assets/images/why-ide-images/publishercontent2.svg"
import publishercontent3 from "../../assets/images/why-ide-images/publishercontent3.svg"
import advertizerscontent1 from "../../assets/images/why-ide-images/advertiserscontent1.svg"
import advertizerscontent2 from "../../assets/images/why-ide-images/advertiserscontent2.svg"
import advertizerscontent3 from "../../assets/images/why-ide-images/advertiserscontent3.svg"
import publisherimg from "../../assets/images/why-ide-images/publisheradvertiser-mob.svg"
import NewFooter from '../footer/NewFooter'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import VisibilitySensor from 'react-visibility-sensor';
import Slider from 'react-slick'




const CustomLoadingAnimation = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '300px' }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            <p style={{ marginTop: '10px' }}>Loading...</p>
        </div>
    )
}




const Counter = ({ number, title, mainSuffix, additionalSuffix, additionalSuffixColor }) => {

    const combinedSuffix = additionalSuffix ? `${mainSuffix}${additionalSuffix}` : mainSuffix;
    return (
        <div className="number">
            <CountUp duration={10} className="counter" end={number}
                suffix={combinedSuffix} >
                {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                        <span className="counter" ref={countUpRef} />
                    </VisibilitySensor>
                )}
            </CountUp>

            <span>{title}</span>
        </div>
    )
}



const WhyIDE = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [animation, setAnimation] = useState(true);
    const [line, setLine] = useState([false]);


    // Loading Animation 
    const [loadingAnimation, setLoadingAnimation] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts or updates

        setLoadingAnimation(true)
        setTimeout(() => {
            setLoadingAnimation(false)
        }, 500)

    }, []);


    useEffect(() => {
        const timeoutIds = [];
        const delays = [2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000]; // Adjust delays as needed

        delays.forEach((delay, index) => {
            const timeoutId = setTimeout(() => {
                setLine(prevState => {
                    const newLine = [...prevState];
                    newLine[index] = true;
                    return newLine;
                });

            }, delay);
            timeoutIds.push(timeoutId);
        });

        return () => {
            timeoutIds.forEach(timeoutId => clearTimeout(timeoutId));
        };
    }, []);


    const SliderData = [
        {
            SrNo: "1",
            Id: "01",
            Title: "Google Partner",
            Description: "Certified expertise for effective Google advertising strategies,boosting your online presence and results.",
            Logo: gpartner
        },
        {
            SrNo: "2",
            Id: "02",
            Title: "Ad Exchange partner",
            Description: " Unlock premium ad opportunities, enhancing your revenue and reach through strategic partnerships.",
            Logo: ads
        },
        {
            SrNo: "3",
            Id: "03",
            Title: "Payment Security",
            Description: "Trustworthy transactions safeguard your financial interests, ensuring a secure and reliable partnership.",
            Logo: payment
        },
        {
            SrNo: "4",
            Id: "04",
            Title: "Expert Consultation",
            Description: " Benefit from tailored guidance, leveraging our industry insights for your success.",
            Logo: consultation
        },
        {
            SrNo: "5",
            Id: "05",
            Title: "3x Return On Investment",
            Description: "Achieve impressive returns, tripling your investments through our proven strategies and campaigns.",
            Logo: investment
        },
        {
            SrNo: "6",
            Id: "06",
            Title: "Proven Track Record",
            Description: " Our successful history speaks for itself, providing assurance of our commitment to excellence.",
            Logo: record
        },
    ]


    const settings = {
        dots: false,
        infinite: true,
        centerMode: false,
        speed: 2500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        draggable: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2500,
        afterChange: (index) => setCurrentSlide(index)
    }

    return (
        <div>


            {
                loadingAnimation ?
                    (
                        <CustomLoadingAnimation />
                    )
                    :
                    (

                        <div>
                            <div style={{ position: "fixed", zIndex: 999, marginTop: "-5px" }}>
                                <NavigationBar />
                            </div>
                            <div className='why-ide-ide-container'>
                                <div className='ideplanebg'>
                                    <img src={bgimg}></img>
                                </div>
                                <div className='slidediv'>
                                    {/* <div className='slidediv'> */}
                                    <div className='slidedivcontent'>
                                        <Slider {...settings} initialSlide={0}>

                                            {
                                                SliderData && (
                                                    SliderData.map((slide, index) => (
                                                        <div key={index} className='slideanimationdiv'>
                                                            {slide && (
                                                                <div className='slidetextmaindiv' style={{ display: "flex", marginLeft: "7%" }}>
                                                                    <span style={{ fontSize: "36px", color: "skyblue", fontWeight: "bold", }}>{slide.Id}</span>
                                                                    <h1 className='nameheading' style={{ marginLeft: "40px", fontSize: "36px", marginTop: "2px" }}>{slide.Title}</h1>
                                                                </div>
                                                            )}
                                                            {slide && (
                                                                <div className='partner-content'>
                                                                    <p>
                                                                        {slide.Description}
                                                                    </p>
                                                                </div>
                                                            )}

                                                        </div>
                                                    ))
                                                )
                                            }

                                        </Slider>


                                    </div>


                                    <div style={{ width: "50%" }}>
                                        <Slider {...settings} initialSlide={0}>
                                            {SliderData.map((slide, index) => (
                                                <div key={index} className='slidedivimage' style={{ width: "100%" }}>
                                                    {slide && (
                                                        <img style={{ width: "100%", marginLeft: "-100px" }} src={slide.Logo} alt={`Logo ${slide.Id}`}></img>
                                                    )}
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>

                                <div className='blur-image-ide'>
                                    <img src={blurimage}></img>
                                </div>
                            </div>
                            {/* ==================================main section SliderData mobile start ====================== */}
                            <div className='why-ide-ide-container-mob'>
                                <div className='ideplanebgmob'>
                                    <img src={bgimg}></img>
                                </div>
                                <div className='slidedivmob'>
                                    <div className='slidedivcontentmob'>
                                        <div className='slidedivimagemob'>
                                            <img src={SliderData[currentSlide].Logo}></img>
                                        </div>
                                        <div style={{ marginTop: "100px", height: "250px" }} className={`slideanimationdiv ${animation ? 'slideanimationdiv' : ''}`}>
                                            <div style={{ display: "flex", marginLeft: "5%" }}>
                                                <span style={{ fontSize: "28px", color: "skyblue", fontWeight: "bold", }}>{SliderData[currentSlide].Id}</span>
                                                <h1 className='nameheading' style={{ marginLeft: "40px", fontSize: "28px", marginTop: "2px" }}>{SliderData[currentSlide].Title}</h1>
                                            </div>
                                            <div className='partner-contentmob'>
                                                <p>
                                                    {SliderData[currentSlide].Description}
                                                </p>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                                <div className='blur-image-ide'>
                                    <img src={blurimage}></img>
                                </div>
                            </div>

                            {/* ================================counter======================================== */}

                            <div className='whyide-counter'>
                                <div className='card-style-counter'>
                                    <div className='card-counter'>
                                        <div className='counter-wrapper'>
                                            <div className='counter-div'>
                                                <div className='numbers'>
                                                    <Counter number={6} title="Impression Served" mainSuffix="B" additionalSuffix="+" additionalSuffixColor="red" />
                                                </div>
                                            </div>
                                            <div className='counter-div'>
                                                <div className='numbers'>
                                                    <Counter number={25} title="Official Partners" mainSuffix="" additionalSuffix="+" />
                                                </div>                    </div>
                                            <div className='counter-div'>
                                                <div className='numbers'>
                                                    <Counter number={4000} title="App + Web Inventries" mainSuffix="" additionalSuffix="+" />
                                                </div>                    </div>
                                            <div className='counter-div'>
                                                <div className='numbers'>
                                                    <Counter number={3} title="AD Spend" mainSuffix="M$" additionalSuffix="+" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id='searchele1' className='elements'>
                                <img src={searchele}></img>
                            </div>

                            {/* ===============================Counter MObile Start ================= */}
                            <div className='countermobide'>
                                <div className='card-style-counter'>
                                    <div className='card-counter'>
                                        <div className='counter-wrapper'>
                                            <div>
                                                <div className='counter-div'>
                                                    <div className='numbers'>
                                                        <Counter number={6} title="Impression Served" mainSuffix="B" additionalSuffix="+" additionalSuffixColor="red" />
                                                    </div>
                                                </div>
                                                <div className='counter-div'>
                                                    <div className='numbers'>
                                                        <Counter number={25} title="Official Partners" mainSuffix="" additionalSuffix="+" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='counter-div'>
                                                    <div className='numbers'>
                                                        <Counter number={4000} title="App + Web Inventries" mainSuffix="" additionalSuffix="+" />
                                                    </div>                    </div>
                                                <div className='counter-div'>
                                                    <div className='numbers'>
                                                        <Counter number={3} title="Ad Spend" mainSuffix="M$" additionalSuffix="+" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* ====================================publisher======================== */}
                            <div className='publisher-advertizer-section'>
                                <div className='publishers'>
                                    <div className={line[1] ? "publisher-tag" : "hidden"}>
                                        <img src={publishertag}></img>
                                    </div>
                                    <div className={line[3] ? "publisher-content1" : "hidden"}>
                                        <div className='publishercontentimage'>
                                            <img src={publishercontent1}></img>
                                        </div>

                                    </div>
                                    <div className={line[5] ? "publisher-content1" : "hidden"}>
                                        <div className='publishercontentimage'>
                                            <img src={publishercontent2}></img>
                                        </div>

                                    </div>
                                    <div className={line[7] ? "publisher-content1" : "hidden"}>
                                        <div className='publishercontentimage'>
                                            <img src={publishercontent3}></img>
                                        </div>

                                    </div>
                                </div>
                                <div className={line[0] ? "line" : "hidden"}>
                                    <div className='center-line'>
                                        <img src={centerline}></img>
                                    </div>
                                </div>
                                {/* =================advertizer================ */}
                                <div className='advertizer'>
                                    <div className={line[2] ? "advertizer-tag" : "hidden"}>
                                        <img src={advertizertag}></img>
                                    </div>

                                    <div className={line[4] ? "advertizer-content1" : "hidden"}>

                                        <div className='advertizercontentimage'>
                                            <img src={advertizerscontent1}></img>
                                        </div>
                                    </div>
                                    <div className={line[6] ? "advertizer-content1" : "hidden"}>

                                        <div className='advertizercontentimage'>
                                            <img src={advertizerscontent2}></img>
                                        </div>
                                    </div>
                                    <div className={line[8] ? "advertizer-content1" : "hidden"}>

                                        <div className='advertizercontentimage'>
                                            <img src={advertizerscontent3}></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* ==================================Publisher mobile start ================== */}
                            <div className='publisher-advertizer-section-mob'>
                                <img src={publisherimg}></img>
                            </div>

                            {/* ==================================Publisher mobile end ================== */}



                            <div id='bagele1' className='elements'>
                                <img src={bagele}></img>
                            </div>
                            <div className='footerdiv'>
                                <NewFooter />
                            </div>


                        </div>
                    )

            }
        </div >
    )
}

export default WhyIDE
