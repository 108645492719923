import React, { useEffect, useState, useRef } from 'react'
import "../home/Home.css"
import googleads from "../../assets/images/homepage-images/googleads.svg"
import Cardslide from '../../component/cardslide/Cardslide'
import blurimage from "../../assets/images/homepage-images/blureffect.svg"
import bagele from "../../assets/images/elements/bag-ele.svg"
import searchele from "../../assets/images/elements/search-ele.svg"
import levelupele from "../../assets/images/elements/level-ele.svg"
import aboutname from "../../assets/images/about-images/aboutname.svg"
import servicename from "../../assets/images/services/servicesname.svg"
import uiuximg from "../../assets/images/services/uiux.svg"
import appdev from "../../assets/images/services/appdevelop.svg"
import digimarketingimg from "../../assets/images/services/digimarketing.svg"
import appmonetizationimg from "../../assets/images/services/appmonetization.svg"
import chooseusname from "../../assets/images/whychooseus-images/whyusname.svg"
import { Button, Col, Row, Spin, message } from 'antd'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import gpartner from "../../assets/images/whychooseus-images/googlepartner.svg"
import ads from "../../assets/images/whychooseus-images/ads.svg"
import payment from "../../assets/images/whychooseus-images/payment.svg"
import consultation from "../../assets/images/whychooseus-images/consultation.svg"
import investment from "../../assets/images/whychooseus-images/return.svg"
import record from "../../assets/images/whychooseus-images/record.svg"
import bulbele from "../../assets/images/elements/bulb.svg"
import circleele from "../../assets/images/elements/circle-ele.svg"
import jobopeningicon from "../../assets/images/jobopening-images/job-opening-icon.svg"
import jobopeningname from "../../assets/images/jobopening-images/jobopeningname.svg"
import gallaryname from "../../assets/images/ourgallary-images/gallary.svg"
import Slider from "react-slick";// import 'swiper/css/swiper.css'
import line from "../../assets/images/gallary-images/Line 6.svg"
import bgimghome from "../../assets/images/homepage-images/Elementbgnew.gif"
import '../../../node_modules/slick-carousel/slick/slick.css'
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons'
import { Form, Input, Modal, Upload } from 'antd'
import NavigationBar from "../../component/NavigationBar"
import { Link } from 'react-router-dom'
import NewFooter from '../footer/NewFooter'
import { useGetJobOpeningDataQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";
import { useGetAboutVideoQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";
import { usePostCandidateFormMutation } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi'
import { useGetEventCoverDataQuery } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi'






const CustomLoadingAnimation = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '300px' }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            <p style={{ marginTop: '10px' }}>Loading...</p>
        </div>
    )
}


const Home = () => {

    const [form] = Form.useForm();

    // Loading Animation 
    const [loadingAnimation, setLoadingAnimation] = useState(true)
    const [videoUrl, setVideoUrl] = useState(null)
    const [eventImages, setEventImages] = useState(null)

    const [jobOpeningApiData, setJobOpeningApiData] = useState(null)
    const [uiUxDesigner, setUiUxDesigner] = useState(null)
    const [android, setAndroid] = useState(null)
    const [flutter, setFlutter] = useState(null)
    const [appTester, setAppTester] = useState(null)

    const [currentIndex, setCurrentIndex] = useState(null);




    const [jobopeningFormModal, setJobopeningFormModal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [candApplicationFor, setCandApplicationFor] = useState('')
    const [candName, setCandName] = useState('')
    const [candEmail, setCandEmail] = useState('')
    const [candContact, setCandContact] = useState('')
    const [candExp, setCandExp] = useState('')
    const [candDesig, setCandDesig] = useState('')
    const [fileList, setFileList] = useState([])

    // =========================== Api call to get Job Data Start here ===========================
    const { data: jobOpeningdata, isLoading: isLoadingJobOpeningdata, isSuccess: isSuccessJobOpeningdata, refetch: refetchJobOpeningdata } = useGetJobOpeningDataQuery()
    const { data: aboutVideoUrl, isLoading: isLoadingAboutVideoUrl, isSuccess: isSuccessAboutVideoUrl } = useGetAboutVideoQuery()
    const { data: eventData, isLoading: isLoadingEventData, isSuccess: isSuccessEventData } = useGetEventCoverDataQuery()
    const isDataLoaded = isSuccessAboutVideoUrl && isSuccessJobOpeningdata;

    useEffect(() => {
        handleToFetchJobdata()
        handleToFetchAboutData()
        handleToFetchEventData()
        // handlePlayVideo()  //function to play about video
    }, [jobOpeningdata, aboutVideoUrl, eventData, isSuccessJobOpeningdata])

    const handleToFetchJobdata = () => {
        if (jobOpeningdata && isSuccessJobOpeningdata) {
            setJobOpeningApiData(jobOpeningdata)

            setUiUxDesigner(jobOpeningdata[0])
            setAndroid(jobOpeningdata[1])
            setFlutter(jobOpeningdata[2])
            setAppTester(jobOpeningdata[3])


            setLoadingAnimation(true)
            setTimeout(() => {
                setLoadingAnimation(false)
                // setLoadingAnimation(true)
            }, 50)

        }
    }
    const handleToFetchAboutData = () => {
        if (aboutVideoUrl && isSuccessAboutVideoUrl) {
            setVideoUrl(aboutVideoUrl.about_video);
        }
    }

    const handleToFetchEventData = () => {
        if (eventData && isSuccessEventData) {
            setEventImages(eventData)

            setCurrentIndex(eventData.length - 1)

        }
    }

    // ================== Custom Slider Start 

    const handlePrevSlide = () => {
        if (eventImages) {

            setCurrentIndex((prevIndex) => (prevIndex === eventImages.length - 1 ? 0 : prevIndex + 1));

        }
    };

    const handleNextSlide = () => {
        if (eventImages) {
            setCurrentIndex((prevIndex) => (prevIndex === 0 ? eventImages.length - 1 : prevIndex - 1));

        }
    };
    // ================== Custom Slider Ends 



    // API Call for form 
    const [createPost, { isLoading, isError }] = usePostCandidateFormMutation();

    const handleModalOpen = (id, application_for) => {
        setCandApplicationFor(application_for)
        showModal(true)
    }



    const handleCandidateForm = async () => {

        if (!candName || !candEmail || !candContact || !candExp || !candDesig || !fileList[0]) {

            if (!candName) {
                message.warning('Candidate name required .');
                return false
            }
            if (!candEmail) {
                message.warning('Candidate email required .');
                return false
            }
            if (!candContact) {
                message.warning('Candidate contact required .');
                return false
            }
            if (!candExp) {
                message.warning('Candidate experience required .');
                return false
            }
            if (!candDesig) {
                message.warning('Candidate designation required .');
                return false
            }
            if (!fileList[0]) {
                message.warning('Candidate resume required .');
                return false
            }
        } else {
            try {
                // Calling the `mutate` function with the post data
                let formData = new FormData();
                formData.append('application_for', candApplicationFor)
                formData.append('cand_name', candName)
                formData.append('cand_email', candEmail)
                formData.append('cand_contact', candContact)
                formData.append('cand_exp', candExp)
                formData.append('cand_desig', candDesig)

                // Append each file individually
                fileList.forEach((file, index) => {
                    formData.append('cand_resume', file.originFileObj);
                });

                const res = await createPost(formData);

                // Reset form fields after successful submission

                form.resetFields();
                resetForm()
                setIsModalOpen(false)
                setJobopeningFormModal(false)
                // The post request was successful
                message.success('Successfully Submited');
            } catch (error) {
                // Handle errors here

                message.error('Check your form and submit .');
            }

        }

    }
    const resetForm = () => {
        setCandApplicationFor('')
        setCandName('');
        setCandEmail('');
        setCandContact('');
        setCandExp('');
        setCandDesig('');
        setFileList([]);
    }

    // =========================== Api call to get Job Data Ends Here ===========================



    const Counter = ({ number, title, mainSuffix, additionalSuffix, additionalSuffixColor }) => {

        const combinedSuffix = additionalSuffix ? `${mainSuffix}${additionalSuffix}` : mainSuffix;
        return (
            <div className="number">
                <CountUp duration={10} className="counter" end={number} redraw={true}
                    suffix={combinedSuffix} >

                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span className="counter" ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                </CountUp>

                <span>{title}</span>
            </div>
        )
    }



    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts or updates

    }, []);


    const [isButtonDisabled, setButtonDisabled] = useState(false);


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);

    };


    const settings = {
        slidesToShow: 3,
        slidesToScroll: true,
        swipeToSlide: true,
        centerMode: true,
        centerPadding: '-1px',
        infinite: true,
        // prevArrow: <CustomPrevArrow />,
        // nextArrow: <CustomNextArrow />,
        speed: 1000,
        dots: false,
        focusOnSelect: true,
        draggable: true,
        swipe: true,
        arrows: true,
        // autoplaySpeed: 5000,
        // autoplay: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: true,
                    infinite: true,
                    swipeToSlide: true,
                    draggable: true,
                    dots: false,
                    arrows: false,
                    marginLeft: '20px',
                }
            },
        ]

    };
    const settingsgallerymob = {
        dots: false,
        infinite: true,
        centerMode: true,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: true,
        initialSlide: 0,
        centerPadding: '30px',
        draggable: true,
        arrows: false,
        marginLeft: '10px',
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    draggable: true,
                    dots: false,
                    marginLeft: '20px',
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    draggable: true,
                    dots: false,
                    marginLeft: '20px',
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    draggable: true,
                    dots: false,
                    marginLeft: '20px',
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    draggable: true,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 630,
                settings: {
                    slidesToShow: 1,
                    draggable: true,
                    slidesToScroll: 1,
                    marginLeft: '20px',
                }
            },

        ]
    };


    const jobsettings = {
        dots: false,
        infinite: true,
        centerMode: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        initialSlide: 0,
        draggable: true,
        arrows: false,
        swipe: true,
        marginLeft: '20px',
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    centerMode: false,
                    infinite: true,
                    draggable: true,
                    dots: false,
                    marginLeft: '20px',
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: false,
                    draggable: true,
                    dots: false,
                    marginLeft: '20px',
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                    infinite: true,
                    draggable: true,
                    dots: false,
                    marginLeft: '20px',
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    centerMode: false,
                    slidesToScroll: 1,
                    draggable: true,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 630,
                settings: {
                    slidesToShow: 1,
                    draggable: true,
                    slidesToScroll: 1,
                    marginLeft: '50px',
                }
            },

        ]
    };



    const chooseUsRef = useRef(null);

    // Intersection Observer callback function
    const handleIntersection = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // When main-chooseus-container is in view, start your counting logic
                console.log('main-chooseus-container is in view');
                // Add your counting logic here
            }
        });
    };

    // Effect to observe when main-chooseus-container comes into view
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Trigger when 50% of the element is visible
        };

        const observer = new IntersectionObserver(handleIntersection, options);
        if (chooseUsRef.current) {
            observer.observe(chooseUsRef.current);
        }

        // Clean up observer on component unmount
        return () => {
            if (chooseUsRef.current) {
                observer.unobserve(chooseUsRef.current);
            }
        };
    }, [chooseUsRef]);




    return (
        <>

            {
                loadingAnimation && bgimghome ?
                    (
                        <CustomLoadingAnimation />
                    )
                    :
                    (
                        <div>

                            <div style={{ position: "fixed", zIndex: 999, marginTop: "-5px" }}>
                                <NavigationBar />
                            </div>
                            {/* // <div className='home-page-container' > */}
                            <div className='container' style={{ paddingTop: '140px' }}>

                                {/* =================Home Container desktop============== */}

                                <div className='desk-container'>

                                    <div className='main-home-container' style={{ marginTop: '30px' }}>
                                        <div className='home'>
                                            <div className='home-content'>
                                                <div className='home-text'>
                                                    <h1>We Drive The</h1>
                                                    <h1 style={{ color: "#0090E0" }}>Market</h1>
                                                    <p>Elevating brands through the power of exceptional  </p>
                                                    <p>app development, design, and marketing. </p>
                                                </div>

                                                <div className="g-ads-img">
                                                    <img src={googleads}></img>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex" }} className='blur-image-home'>
                                                <div >
                                                    <img className='blur-img-img' src={blurimage}></img>
                                                </div>
                                                <div className='homebganimation' style={{}}>
                                                    <img className='animationgif' style={{}} src={bgimghome}></img>
                                                </div>
                                                <video src=''></video>
                                            </div>
                                        </div>

                                        <div className='slider'>
                                            <span >Over 400+ Companies Worldwide joined with <span style={{ color: "#0090E0" }}>ItsDigitalEra</span></span>
                                            <div className='slider-container'>
                                                <Cardslide />
                                            </div>
                                        </div>
                                        <div className='elements' style={{ textAlign: "start" }}>
                                            <img src={bagele}></img>
                                        </div>
                                    </div>


                                    {/* =============== About Container desktop =============== */}

                                    <div className='main-about-container'>
                                        <div className='about-content'>
                                            <div style={{ display: "flex" }}>
                                                <div className='about-image'>


                                                    {/* <video src={videoUrl} autoPlay={true} muted={true} loop type="video/mov" className='videoabout' style={{ width: "100%", zIndex: 1 }} id="myVideo" /> */}
                                                    <img src={videoUrl}  style={{ width: "100%", zIndex: 1, borderTopRightRadius:"100px", borderBottomLeftRadius:"100px" }} id="myVideo" />

                                                </div>
                                                <div className='about-info'>
                                                    <div style={{ textAlign: "start" }}>
                                                        <img src={aboutname}></img>
                                                    </div>
                                                    <h2>We're a Google Partner agency, experts in ad operations, app development and  boosting your app's success
                                                        by fine-tuning ARPU, ensuring every user contributes to
                                                        your app's long-term revenue stream.
                                                    </h2>


                                                    <div className='about-btn'>

                                                        <Link to='/about'><Button className='know-btn'>Know More</Button></Link>
                                                    </div>

                                                </div>


                                                <div className='elements' style={{ textAlign: "start", marginLeft: "50px" }}>
                                                    <img src={searchele}></img>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='elements' style={{ marginLeft: "300px", marginTop: "40px" }}>
                                            <img src={levelupele}></img>
                                        </div>
                                    </div>

                                    {/* =========================Services Container  desktop==================== */}

                                    <div className='main-services-container'>
                                        <div style={{ marginTop: "1%" }}>
                                            <img src={servicename}></img>
                                        </div>

                                        <div className='blur-image-service1'>
                                            <img src={blurimage}></img>
                                        </div>

                                        <div className='services-content'>
                                            <div className='card-style'>
                                                <div className='card'>
                                                    <div className='service-content-div'>
                                                        <div className='main-content'>
                                                            <img src={uiuximg}></img>
                                                        </div>
                                                        <div style={{ textAlign: "start", paddingLeft: "50px" }}>
                                                            <h2 style={{ color: "black" }}>UI/UX Designing</h2>
                                                            <div style={{ width: "90%" }}>
                                                                <p style={{ fontSize: "22px", fontWeight: "400", lineHeight: "30px", color: "#5D5D5D" }}>We design stunning, user-centric apps with a focus on meeting your unique needs,
                                                                    enhancing user engagement for intuitive and delightful experiences.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='card-style1'>
                                                <div className='card1'>
                                                    <div className='service-content-div1'>
                                                        <div className='main-content'>
                                                            <img src={appdev}></img>
                                                        </div>
                                                        <div style={{ textAlign: "start", paddingLeft: "50px" }}>
                                                            <h2>App Development</h2>
                                                            <div style={{ width: "90%" }}>
                                                                <p style={{ fontSize: "20px", fontWeight: "400", lineHeight: "30px", color: "#5D5D5D" }}>Developing tailored mobile applications to meet your business needs, focusing on functionality,
                                                                    scalability, and delivering smooth user experiences.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>


                                        <div className='services-content1'>
                                            <div className='card-style1'>
                                                <div className='card1'>
                                                    <div className='service-content-divbottom'>
                                                        <div className='main-content'>
                                                            <img src={digimarketingimg}></img>
                                                        </div>
                                                        <div style={{ textAlign: "start", paddingLeft: "50px" }}>
                                                            <h2>Digital Marketing</h2>
                                                            <div style={{ width: "90%" }}>
                                                                <p style={{ fontSize: "20px", fontWeight: "400", lineHeight: "30px", color: "#5D5D5D" }}>Scaling app’s reach using Automotive Campaigns optimized by Ad-Ops experts.
                                                                    Get more User engagement and Installs on your application with Google/Fb Ads.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='card-style3'>
                                                <div className='card'>
                                                    <div className='service-content-div1bottom'>
                                                        <div className='main-content'>
                                                            <img src={appmonetizationimg}></img>
                                                        </div>
                                                        <div style={{ textAlign: "start", paddingLeft: "50px" }}>
                                                            <h2>App Monetization</h2>
                                                            <div style={{ width: "80%" }}>
                                                                <p style={{ fontSize: "20px", fontWeight: "400", lineHeight: "30px", color: "#5D5D5D" }}>Increasing your app’s ARPDAU with ROI-Driven Technologies,
                                                                    In-app Purchase Sales(IAP) and Get access to Premium DSPs and Ad Networks.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* ================================================================= */}




                                        {/* ================================================================= */}

                                        <div className='blur-image-service-bottom'>
                                            <img src={blurimage}></img>
                                        </div>

                                    </div>


                                    {/* ==================Why Choose Us container==================== */}
                                    <div className='main-chooseus-container' ref={chooseUsRef}>
                                        <div style={{ textAlign: "start", marginLeft: "13%" }}>
                                            <img src={chooseusname}></img>
                                        </div>
                                        <div className='card-style-counter'>
                                            <div className='card-counter'>
                                                <div className='counter-wrapper'>
                                                    <div className='counter-div'>
                                                        <div className='numbers'>
                                                            <Counter number={6} title="Impression Served" mainSuffix="B" additionalSuffix="+" additionalSuffixColor="red" />
                                                        </div>
                                                    </div>
                                                    <div className='counter-div'>
                                                        <div className='numbers'>
                                                            <Counter number={25} title="Official Partners" mainSuffix="" additionalSuffix="+" />
                                                        </div>                    </div>
                                                    <div className='counter-div'>
                                                        <div className='numbers'>
                                                            <Counter number={4000} title="App + Web Inventries" mainSuffix="" additionalSuffix="+" />
                                                        </div>                    </div>
                                                    <div className='counter-div'>
                                                        <div className='numbers'>
                                                            <Counter number={3} title="AD Spend" mainSuffix="M$" additionalSuffix="+" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='blur-image-chooseus'>
                                            <img src={blurimage}></img>
                                        </div>

                                        {/* =========================================================== */}
                                        <div>

                                            <div className='elements' style={{ textAlign: "end", marginRight: "13%", marginTop: "3%" }}>
                                                <img src={circleele}></img>
                                            </div>
                                            <div className='chooseus-row'>
                                                <div className='chooseus-col1'>
                                                    <div style={{ display: "flex", marginLeft: "7%" }}>
                                                        <span style={{ fontSize: "36px", color: "skyblue", fontWeight: "bold", }}>01</span>
                                                        <h1 className='nameheading' style={{ marginLeft: "40px", fontSize: "36px", marginTop: "2px" }}> Google Partner</h1>
                                                    </div>
                                                    <div className='partner-content'>
                                                        <p>
                                                            Certified expertise for effective Google advertising strategies,
                                                            boosting your online presence and results.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='chooseus-col'>
                                                    <img src={gpartner}></img>
                                                </div>
                                            </div>
                                            <div className='chooseus-rowsecond'>
                                                <div className='chooseus-colsecond'>
                                                    <img src={ads}></img>
                                                </div>
                                                <div className='chooseus-col1'>
                                                    <div style={{ display: "flex", marginLeft: "7%" }}>
                                                        <span className='namenumber' style={{ fontSize: "36px", color: "skyblue", fontWeight: "bold", marginTop: "10px" }}>02</span>
                                                        <h1 className='nameheading' style={{ marginLeft: "40px", fontSize: "36px", marginTop: "10px" }}> Ad Exchange partner</h1>
                                                    </div>
                                                    <div className='partner-content'>
                                                        <p>
                                                            Unlock premium ad opportunities,
                                                            enhancing your revenue and reach through strategic partnerships.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='chooseus-rowsecond'>
                                                <div className='chooseus-col1'>
                                                    <div style={{ display: "flex", marginLeft: "7%" }}>
                                                        <span className='namenumber' style={{ fontSize: "36px", color: "skyblue", fontWeight: "bold", marginTop: "10px" }}>03</span>
                                                        <h1 className='nameheading' style={{ marginLeft: "40px", fontSize: "36px", marginTop: "10px" }}>Payment Security</h1>
                                                    </div>
                                                    <div className='partner-content'>
                                                        <p>
                                                            Trustworthy transactions safeguard your financial interests,
                                                            ensuring a secure and reliable partnership.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='chooseus-col'>
                                                    <img src={payment}></img>
                                                </div>
                                            </div>

                                            <div className='chooseus-rowsecond'>
                                                <div className='chooseus-colsecond'>
                                                    <img src={consultation}></img>
                                                </div>

                                                <div className='chooseus-col1'>
                                                    <div style={{ display: "flex", marginLeft: "7%" }}>
                                                        <span className='namenumber' style={{ fontSize: "36px", color: "skyblue", fontWeight: "bold", marginTop: "10px" }}>04</span>
                                                        <h1 className='nameheading' style={{ marginLeft: "40px", fontSize: "36px", marginTop: "10px" }}>Expert Consultation</h1>
                                                    </div>
                                                    <div className='partner-content'>
                                                        <p>
                                                            Benefit from tailored guidance,
                                                            leveraging our industry insights for your success.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='blur-image-service-bottom'>
                                                <img src={blurimage}></img>
                                            </div>
                                            <div className='chooseus-rowsecond'>
                                                <div className='chooseus-col1'>
                                                    <div style={{ display: "flex", marginLeft: "7%" }}>
                                                        <span className='namenumber' style={{ fontSize: "36px", color: "skyblue", fontWeight: "bold", marginTop: "10px" }}>05</span>
                                                        <h1 className='nameheading' style={{ marginLeft: "40px", fontSize: "36px", marginTop: "10px" }}> 3x Return On Investment</h1>
                                                    </div>
                                                    <div className='partner-content'>
                                                        <p>
                                                            Achieve impressive returns, tripling your investments
                                                            through our proven strategies and campaigns.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='chooseus-col'>
                                                    <img src={investment}></img>
                                                </div>
                                            </div>
                                            <div className='chooseus-rowsecond'>
                                                <div className='chooseus-colrecord'>
                                                    <img src={record}></img>
                                                </div>
                                                <div className='chooseus-col1'>
                                                    <div style={{ display: "flex", marginLeft: "7%" }}>
                                                        <span className='namenumber' style={{ fontSize: "36px", color: "skyblue", fontWeight: "bold", marginTop: "10px" }}>06</span>
                                                        <h1 className='nameheading' style={{ marginLeft: "40px", fontSize: "36px", marginTop: "10px" }}>Proven Track Record</h1>
                                                    </div>
                                                    <div className='partner-content'>
                                                        <p>
                                                            Our successful history speaks for itself,
                                                            providing assurance of our commitment to excellence.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='elements' style={{ textAlign: "end", marginRight: "13%" }}>
                                                <img src={searchele}></img>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ===========================Job opening Start========================= */}
                                    <div className='main-jobopening-container'>
                                        <div style={{ marginTop: "2%" }}>
                                            <img src={jobopeningname}></img>
                                        </div>
                                        <div className='elements' style={{ textAlign: "start", marginLeft: "15%", marginTop: "-2%" }}>
                                            <img src={bagele}></img>
                                        </div>

                                        <div className='job-row'>
                                            <div className='job-opening-row'>


                                                <Slider  {...jobsettings}>
                                                    {
                                                        jobOpeningApiData && (
                                                            jobOpeningApiData.map((slide, index) => (
                                                                <div key={index} className='jobopening-col' style={{ marginLeft: "30px" }}>

                                                                    <img src={jobopeningicon} alt="" />

                                                                    <h2 style={{ fontSize: "20px" }}>{slide.application_domain}</h2>
                                                                    <h3 style={{ lineHeight: "25px" }}>{slide.job_time} | {slide.job_exp}</h3>
                                                                    <h3>Job Vacancy : {slide.job_vacancy}</h3>
                                                                    <div className='submit-btn'>
                                                                        {slide.job_vacancy === "0" ? (
                                                                            <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                                        ) :
                                                                            (
                                                                                <Button className='submit'
                                                                                    onClick={() => handleModalOpen(1, slide.application_domain)}
                                                                                >Apply Now</Button>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )))}
                                                </Slider>

                                                {/* <Col className='jobopening-col'>
                                                    <img src={jobopeningicon} alt="" />
                                                    <h2 style={{ fontSize: "20px" }}>{android.application_domain}</h2>
                                                    <h3 style={{ lineHeight: "25px" }}>{android.job_time} | {android.job_exp}</h3>
                                                    <h3>Job Vacancy : {android.job_vacancy}</h3>
                                                    <div className='submit-btn'>
                                                        {android.job_vacancy === "0" ? (
                                                            <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                        ) :
                                                            (
                                                                <Button className='submit'
                                                                    onClick={() => handleModalOpen(2, android.application_domain)}
                                                                >Apply Now</Button>
                                                            )
                                                        }
                                                    </div>
                                                </Col>
                                                <Col className='jobopening-col'>
                                                    <img src={jobopeningicon} alt="" />
                                                    <h2 style={{ fontSize: "20px" }}>{flutter.application_domain}</h2>
                                                    <h3 style={{ lineHeight: "25px" }}>{flutter.job_time} | {flutter.job_exp}</h3>
                                                    <h3>Job Vacancy : {flutter.job_vacancy}</h3>
                                                    <div className='submit-btn'>
                                                        {flutter.job_vacancy === "0" ? (
                                                            <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                        ) :
                                                            (
                                                                <Button className='submit'
                                                                    onClick={() => handleModalOpen(3, flutter.application_domain)}
                                                                >Apply Now</Button>
                                                            )
                                                        }
                                                    </div>
                                                </Col>
                                                <Col className='jobopening-col'>
                                                    <img src={jobopeningicon} alt="" />
                                                    <h2 style={{ fontSize: "20px" }}>{appTester.application_domain}</h2>
                                                    <h3 style={{ lineHeight: "25px" }}>{appTester.job_time} | {appTester.job_exp}</h3>
                                                    <h3>Job Vacancy : {appTester.job_vacancy}</h3>
                                                    <div className='submit-btn'>
                                                        {appTester.job_vacancy === "0" ? (
                                                            <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                        ) :
                                                            (
                                                                <Button className='submit'
                                                                    onClick={() => handleModalOpen(4, appTester.application_domain)}
                                                                >Apply Now</Button>
                                                            )
                                                        }
                                                    </div>
                                                </Col> */}
                                            </div>
                                        </div>
                                    </div>

                                    {/* ===========================Our Gallery Start========================= */}


                                    {/* ================================================================ */}

                                    <div className='main-ourgallery-container'>
                                        <div style={{ textAlign: "start", marginLeft: "13%" }}>
                                            <img src={gallaryname}></img>
                                        </div>

                                        <div className="wrapper">
                                            <div className="center-slider">
                                                <Slider  {...settings}>

                                                    {
                                                        eventImages && (
                                                            eventImages.map((slide, index) => (
                                                                <div key={index}>
                                                                    {slide && (
                                                                        <img style={{ width: "100%" }} src={slide.event_cover_image}></img>
                                                                    )}
                                                                </div>
                                                            ))
                                                        )
                                                    }
                                                </Slider>
                                                <div className='festive' style={{ display: "flex", justifyContent: 'center' }}>
                                                    <img style={{ padding: "10px", marginTop: "7px" }} src={line}></img>
                                                    <h2>
                                                        IDE Events
                                                    </h2>

                                                    <img style={{ padding: "10px", marginTop: "7px" }} src={line}></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                {/* =========================Mobile View==================== */}

                                {/* =================Home Container Mobile view============== */}

                                <div className='home-page-container-mob'>
                                    <div className='home-page-container-mobile'>
                                        <div className='mob-home'>
                                            <div className='blur-image'>
                                                <img src={blurimage}></img>
                                            </div>
                                            <div className='homebganimation' style={{}}>
                                                <img className='animationgif' style={{}} src={bgimghome}></img>
                                            </div>
                                        </div>
                                        <div className='home-text'>
                                            <h1>We Drive The</h1>
                                            <h1 style={{ color: "#0090E0" }}>Market</h1>
                                            <p>Elevating brands through the power of   </p>
                                            <p>exceptional app development, design, </p>
                                            <p>and marketing.</p>
                                        </div>
                                        <div className="g-ads-img">
                                            <img src={googleads}></img>
                                        </div>

                                        <div className='blur-image-slider'>
                                            <img src={blurimage}></img>
                                        </div>


                                    </div>
                                    <div className='slider'>
                                        <div style={{ width: "80%", marginLeft: "8%" }}>
                                            <span>Over 400+ Companies Worldwide joined with <span style={{ color: "#0090E0" }}>ItsDigitalEra</span></span>
                                        </div>
                                        <div className='slider-container'>
                                            <Cardslide />
                                        </div>
                                    </div>


                                    {/* =============== About Container Mobile view =============== */}
                                    <div className='main-about-container-mobile'>
                                        <div className='about-info'>
                                            <div style={{ textAlign: "start" }}>
                                                <img style={{ height: "50px" }} src={aboutname}></img>
                                            </div>
                                            <h2>We're a Google Partner agency, experts in ad operations, app development and  boosting your app's success
                                                by fine-tuning ARPU, ensuring every user contributes to
                                                your app's long-term revenue stream.
                                            </h2>
                                        </div>
                                        <div className='about-btn'>
                                            <Link to='/about'><Button className='know-btn'>Know More</Button></Link>
                                        </div>
                                        <div className='about-image'>
                                            {/* <video src={videoUrl} autoPlay={true} muted={true} loop type="video/mov" style={{ width: "90%", borderTopRightRadius: "50px", borderBottomLeftRadius: "50px" }} id="myVideo" /> */}
                                            <img src={videoUrl}  style={{ width: "100%", zIndex: 1, borderTopRightRadius:"100px", borderBottomLeftRadius:"100px" }} id="myVideo" />

                                        </div>
                                    </div>


                                    {/* =========================Services Container  Mobile view==================== */}
                                    <div className='main-services-container-mobile'>
                                        <div style={{ paddingTop: "1%" }}>
                                            <img style={{ height: "40px" }} src={servicename}></img>
                                        </div>

                                        <div className='elements' style={{ marginLeft: "270px" }}>
                                            <img style={{ height: "60px" }} src={levelupele}></img>
                                        </div>

                                        <div className='card-style'>
                                            <div className='card'>
                                                <div className='service-content-div'>
                                                    <div className='main-content'>
                                                        <img src={uiuximg}></img>
                                                    </div>
                                                    <div style={{ textAlign: "start", paddingLeft: "40px" }}>
                                                        <h3 style={{ color: "black" }}>UI/UX Designing</h3>
                                                        <div style={{ width: "90%" }}>
                                                            <p style={{ fontSize: "16px", fontWeight: "400", lineHeight: "25px", color: "#5D5D5D" }}>We design stunning, user-centric apps with a focus on meeting your unique needs,
                                                                enhancing user engagement for intuitive and delightful experiences.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='card-stylemob'>
                                            <div className='cardmob'>
                                                <div className='service-content-div1'>
                                                    <div className='main-content'>
                                                        <img src={appdev}></img>
                                                    </div>
                                                    <div style={{ textAlign: "start", paddingLeft: "40px" }}>
                                                        <h3>App Development</h3>
                                                        <div style={{ width: "95%" }}>
                                                            <p style={{ fontSize: "16px", fontWeight: "400", lineHeight: "25px", color: "#5D5D5D" }}>Developing tailored mobile applications to meet your business needs, focusing on functionality,
                                                                scalability, and delivering smooth user experiences.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='card-style'>
                                            <div className='card'>
                                                <div style={{ marginTop: "0px" }} className='service-content-div'>
                                                    <div className='main-content'>
                                                        <img src={digimarketingimg}></img>
                                                    </div>
                                                    <div style={{ textAlign: "start", paddingLeft: "40px" }}>
                                                        <h3 style={{ color: "black" }}>Digital Marketing</h3>
                                                        <div style={{ width: "95%" }}>
                                                            <p style={{ fontSize: "16px", fontWeight: "400", lineHeight: "25px", color: "#5D5D5D" }}>Scaling app’s reach using Automotive Campaigns optimized by Ad-Ops experts.
                                                                Get more User engagement and Installs on your application with Google/Fb Ads.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='blur-image-service2'>
                                            <img src={blurimage}></img>
                                        </div>

                                        <div style={{ marginTop: "120px" }} className='card-stylemob'>
                                            <div className='cardmob'>
                                                <div style={{ marginTop: "0px" }} className='service-content-div2'>
                                                    <div className='main-content'>
                                                        <img src={appmonetizationimg}></img>
                                                    </div>
                                                    <div style={{ textAlign: "start", paddingLeft: "40px" }}>
                                                        <h3>App Monetization</h3>
                                                        <div style={{ width: "95%" }}>
                                                            <p style={{ fontSize: "16px", fontWeight: "400", lineHeight: "25px", color: "#5D5D5D" }}>Increasing your app’s ARPDAU with ROI-Driven Technologies,
                                                                In-app Purchase Sales(IAP) and Get access to Premium DSPs and Ad Networks.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* =======================why choose us start mobile view============================= */}

                                    <div className='main-chooseus-container-mobile'>
                                        <div style={{ textAlign: "start", marginLeft: "7%" }}>
                                            <img style={{ height: "50px" }} src={chooseusname}></img>
                                        </div>

                                        <div className='elements' style={{ marginLeft: "270px", marginTop: "-20px" }}>
                                            <img style={{ height: "60px" }} src={bulbele}></img>
                                        </div>

                                        <div className='card-style-counter'>
                                            <div className='card-counter'>
                                                <div className='counter-wrapper'>
                                                    <div>
                                                        <div className='counter-div'>
                                                            <div className='numbers'>
                                                                <Counter number={6} title="Impression Served" mainSuffix="B" additionalSuffix="+" additionalSuffixColor="red" />
                                                            </div>
                                                        </div>
                                                        <div className='counter-div'>
                                                            <div className='numbers'>
                                                                <Counter number={25} title="Official Partners" mainSuffix="" additionalSuffix="+" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='counter-div'>
                                                            <div className='numbers'>
                                                                <Counter number={4000} title="App + Web Inventries" mainSuffix="" additionalSuffix="+" />
                                                            </div>                    </div>
                                                        <div className='counter-div'>
                                                            <div className='numbers'>
                                                                <Counter number={3} title="Ad Spend" mainSuffix="M$" additionalSuffix="+" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ marginTop: "70px", }}>
                                            <div>
                                                <div style={{ display: "flex", marginLeft: "7%" }}>
                                                    <span style={{ fontSize: "26px", color: "skyblue", fontWeight: "bold", }}>01</span>
                                                    <h1 style={{ marginLeft: "40px", fontSize: "26px", marginTop: "2px" }}> Google Partner</h1>
                                                </div>
                                                <div className='partner-content-mob'>
                                                    <p>
                                                        Certified expertise for effective Google advertising strategies,
                                                        boosting your online presence and results.
                                                    </p>
                                                </div>
                                                <div className='chooseus-col'>
                                                    <img src={gpartner}></img>
                                                </div>

                                            </div>


                                            <div style={{ marginTop: "30px", }}>
                                                <div style={{ display: "flex", marginLeft: "7%" }}>
                                                    <span style={{ fontSize: "26px", color: "skyblue", fontWeight: "bold", marginTop: "10px" }}>02</span>
                                                    <h1 style={{ marginLeft: "40px", fontSize: "26px", marginTop: "14px" }}> Ad Exchange Partner</h1>
                                                </div>
                                                <div className='partner-content-mob'>
                                                    <p>
                                                        Unlock premium ad opportunities,
                                                        enhancing your revenue and reach through strategic partnerships.
                                                    </p>
                                                </div>

                                                <div className='chooseus-colsecond'>
                                                    <img src={ads}></img>
                                                </div>
                                            </div>

                                            <div className='blur-image-cooseus-mob'>
                                                <img src={blurimage}></img>
                                            </div>
                                            <div className='elements' style={{ textAlign: "start", marginLeft: "15%", marginTop: "-2%" }}>
                                                <img style={{ height: "60px" }} src={bagele}></img>
                                            </div>

                                            <div className='payment-se' style={{ marginTop: "100px", }}>
                                                <div style={{ display: "flex", marginLeft: "7%" }}>
                                                    <span style={{ fontSize: "26px", color: "skyblue", fontWeight: "bold", marginTop: "10px" }}>03</span>
                                                    <h1 style={{ marginLeft: "40px", fontSize: "26px", marginTop: "10px" }}>Payment Security</h1>
                                                </div>
                                                <div className='partner-content-mob'>
                                                    <p>
                                                        Trustworthy transactions safeguard your financial interests,
                                                        ensuring a secure and reliable partnership.
                                                    </p>
                                                </div>

                                                <div className='chooseus-col'>
                                                    <img src={payment}></img>
                                                </div>
                                            </div>

                                            <div style={{ marginTop: "30px", }}>
                                                <div style={{ display: "flex", marginLeft: "7%" }}>
                                                    <span style={{ fontSize: "26px", color: "skyblue", fontWeight: "bold", marginTop: "10px" }}>04</span>
                                                    <h1 style={{ marginLeft: "10px", fontSize: "26px", marginTop: "10px" }}>Expert Consultation</h1>
                                                </div>
                                                <div className='partner-content-mob'>
                                                    <p>
                                                        Benefit from tailored guidance,
                                                        leveraging our industry insights for your success.
                                                    </p>
                                                </div>

                                                <div className='chooseus-colsecond'>
                                                    <img src={consultation}></img>
                                                </div>
                                            </div>

                                            <div style={{ marginTop: "30px", }}>
                                                <div style={{ display: "flex", marginLeft: "7%" }}>
                                                    <span style={{ fontSize: "26px", color: "skyblue", fontWeight: "bold", marginTop: "10px" }}>05</span>
                                                    <h1 style={{ marginLeft: "10px", fontSize: "26px", marginTop: "10px" }}> 3x Return On Investment</h1>
                                                </div>
                                                <div className='partner-content-mob'>
                                                    <p>
                                                        Achieve impressive returns, tripling your investments
                                                        through our proven strategies and campaigns.
                                                    </p>
                                                </div>

                                                <div className='chooseus-col'>
                                                    <img src={investment}></img>
                                                </div>
                                                <div className='blur-image-cooseus555-mob'>
                                                    <img src={blurimage}></img>
                                                </div>

                                            </div>
                                            <div className='proven-track' style={{ marginTop: "160px", }}>
                                                <div style={{ display: "flex", marginLeft: "7%" }}>
                                                    <span style={{ fontSize: "26px", color: "skyblue", fontWeight: "bold", marginTop: "10px" }}>06</span>
                                                    <h1 style={{ marginLeft: "20px", fontSize: "26px", marginTop: "10px" }}>Proven Track Record</h1>
                                                    <div className='elements' style={{ textAlign: "start", marginLeft: "0px" }}>
                                                        <img style={{ height: "50px" }} src={searchele}></img>
                                                    </div>
                                                </div>
                                                <div className='partner-content-mob'>
                                                    <p>
                                                        Our successful history speaks for itself,
                                                        providing assurance of our commitment to excellence.
                                                    </p>
                                                </div>                        <div className='chooseus-colrecord'>
                                                    <img src={record}></img>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    {/* ============================Job Opening start Mobile view================ */}

                                    <div className='main-jobopening-container-mobile'>
                                        <div style={{ marginTop: "2%" }}>
                                            <img style={{ height: "50px" }} src={jobopeningname}></img>
                                        </div>

                                        <div>
                                            <div className='job-row'>

                                                <div className="center-slider">

                                                    <Slider {...jobsettings}>
                                                        {
                                                            jobOpeningApiData && (
                                                                jobOpeningApiData.map((slide, index) => (
                                                                    <div key={index} className='slide-job'>
                                                                        {slide && (
                                                                            <div className='jobopening-col' style={{ marginLeft: "40px" }}>
                                                                                {/* <div className='jobopeningdiv'> */}
                                                                                <img src={jobopeningicon} alt="" />

                                                                                <h2>{slide.application_domain}</h2>
                                                                                <h3 style={{ lineHeight: "20px" }}>{slide.job_time} | {slide.job_exp}</h3>
                                                                                <h3>Job Vacancy : {slide.job_vacancy}</h3>
                                                                                <div className='submit-btn'>
                                                                                    {slide.job_vacancy === "0" ? (
                                                                                        <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                                                    ) :
                                                                                        (
                                                                                            <Button className='submit'
                                                                                                onClick={() => handleModalOpen(1, slide.application_domain)}
                                                                                            >Apply Now</Button>
                                                                                        )
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )))}
                                                        {/* <div className='slide-job'>
                                                            <div className='jobopening-col' style={{ marginLeft: "40px" }}>
                                                                <img src={jobopeningicon} alt="" />
                                                                <h2>{android.application_domain}</h2>
                                                                <h3 style={{ lineHeight: "20px" }}>{android.job_time} | {android.job_exp}</h3>
                                                                <h3>Job Vacancy : {android.job_vacancy}</h3>
                                                                <div className='submit-btn'>
                                                                    {android.job_vacancy === "0" ? (
                                                                        <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                                    ) :
                                                                        (
                                                                            <Button className='submit'
                                                                                onClick={() => handleModalOpen(2, android.application_domain)}
                                                                            >Apply Now</Button>
                                                                        )
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='slide-job'>
                                                            <div className='jobopening-col' style={{ marginLeft: "40px" }}>
                                                                <img src={jobopeningicon} alt="" />
                                                                <h2>{flutter.application_domain}</h2>
                                                                <h3 style={{ lineHeight: "20px" }}>{flutter.job_time} | {flutter.job_exp}</h3>
                                                                <h3>Job Vacancy : {flutter.job_vacancy}</h3>
                                                                <div className='submit-btn'>
                                                                    {flutter.job_vacancy === "0" ? (
                                                                        <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                                    ) :
                                                                        (
                                                                            <Button className='submit'
                                                                                onClick={() => handleModalOpen(3, flutter.application_domain)}
                                                                            >Apply Now</Button>
                                                                        )
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='slide-job'>
                                                            <div className='jobopening-col' style={{ marginLeft: "40px" }}>
                                                                <img src={jobopeningicon} alt="" />
                                                                <h2>{appTester.application_domain}</h2>
                                                                <h3 style={{ lineHeight: "20px" }}>{appTester.job_time} | {appTester.job_exp}</h3>
                                                                <h3>Job Vacancy : {appTester.job_vacancy}</h3>
                                                                <div className='submit-btn'>
                                                                    {appTester.job_vacancy === "0" ? (
                                                                        <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                                    ) :
                                                                        (
                                                                            <Button className='submit'
                                                                                onClick={() => handleModalOpen(4, appTester.application_domain)}
                                                                            >Apply Now</Button>
                                                                        )
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div> */}

                                                    </Slider>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    {/* =========================Our Gallery start MObile view ===================== */}

                                    <div className='main-ourgallery-container-mob'>
                                        <div style={{ textAlign: "center", marginLeft: "1%" }}>
                                            <img style={{ height: "50px" }} src={gallaryname}></img>
                                        </div>

                                        <div style={{ marginTop: "-60px" }} className="wrapper">
                                            <div className="center-slider">
                                                <Slider  {...settingsgallerymob}>
                                                    {
                                                        eventImages && (


                                                            eventImages.map((slide, index) => (
                                                                <div key={index}>
                                                                    {slide && (
                                                                        <img style={{ width: "100%" }} src={slide.event_cover_image}></img>
                                                                    )}
                                                                </div>

                                                            ))
                                                        )
                                                    }

                                                </Slider>
                                                <div className='festivname' style={{ display: "flex", justifyContent: 'center', marginTop: "-200px" }}>
                                                    <img style={{ padding: "1px", marginTop: "7px", width: "80px" }} src={line}></img>
                                                    <h4>
                                                        IDE Events
                                                    </h4>
                                                    <img style={{ padding: "1px", marginTop: "7px", width: "80px" }} src={line}></img>
                                                </div>
                                            </div>
                                        </div>

                                    </div>




                                </div>


                                {/* =========================Tab View==================== */}

                                {/* =================Tab Container Tab view============== */}

                                <div className='tab-conatiner'>

                                    <div className='desk-container-tab'>
                                        <div className='main-home-container' style={{ marginTop: '40px' }}>
                                            <div className='home'>
                                                <div className='home-content'>
                                                    <div className='home-text'>
                                                        <h1>We Drive The</h1>
                                                        <h1 style={{ color: "#0090E0" }}>Market</h1>
                                                        <p>Elevating brands through the power of exceptional  </p>
                                                        <p>app development, design, and marketing.</p>
                                                    </div>

                                                    <div className="g-ads-img">
                                                        <img src={googleads}></img>
                                                    </div>
                                                </div>
                                                <div className='blur-image'>
                                                    <img src={blurimage}></img>
                                                </div>
                                                <div className='homebganimation' style={{}}>
                                                    <img className='animationgif' style={{}} src={bgimghome}></img>
                                                </div>
                                            </div>

                                            <div className='slider'>
                                                <span >Over 400+ Companies Worldwide joined with <span style={{ color: "#0090E0" }}>ItsDigitalEra</span></span>
                                                <div className='slider-container'>
                                                    <Cardslide />
                                                </div>
                                            </div>
                                            <div className='elements' style={{ textAlign: "start" }}>
                                                <img style={{ height: "80px" }} src={bagele}></img>
                                            </div>
                                        </div>

                                        {/* =============== About Container Tab =============== */}

                                        <div className='main-about-container'>
                                            <div className='about-content'>
                                                <div style={{ display: "flex" }}>
                                                    <div className='about-image'>
                                                        {/* <img style={{ width: "100%" }} src={aboutimg}></img> */}
                                                        {/* <video src={videoUrl} autoPlay={true} muted={true} loop type="video/mov" style={{ width: "90%", borderTopRightRadius: "100px", borderBottomLeftRadius: "100px" }} id="myVideo" /> */}
                                                        <img src={videoUrl}  style={{ width: "100%", zIndex: 1, borderTopRightRadius:"75px", borderBottomLeftRadius:"75px" }} id="myVideo" />

                                                    </div>
                                                    <div className='about-info'>
                                                        <div style={{ textAlign: "start" }}>
                                                            <img style={{ height: "50px" }} src={aboutname}></img>
                                                        </div>
                                                        <h2>We're a Google Partner agency, experts in ad operations, app development and  boosting your app's success
                                                            by fine-tuning ARPU, ensuring every user contributes to
                                                            your app's long-term revenue stream.
                                                        </h2>


                                                        <div className='about-btn'>

                                                            <Button className='know-btn'>Know More</Button>
                                                        </div>

                                                    </div>


                                                    <div className='elements' style={{ textAlign: "start", marginLeft: "0px" }}>
                                                        <img style={{ height: "80px" }} src={searchele}></img>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='elements' style={{ marginLeft: "300px", marginTop: "40px" }}>
                                                <img style={{ height: "80px" }} src={levelupele}></img>
                                            </div>
                                        </div>

                                        {/* =========================Services Container  Tab==================== */}

                                        <div className='main-services-container'>
                                            <div style={{ marginTop: "1%" }}>
                                                <img style={{ height: "50px" }} src={servicename}></img>
                                            </div>

                                            <div className='blur-image-service1'>
                                                <img src={blurimage}></img>
                                            </div>

                                            <div className='services-content'>
                                                <div className='card-style'>
                                                    <div className='card'>
                                                        <div className='service-content-div'>
                                                            <div className='main-content'>
                                                                <img style={{ height: "70px" }} src={uiuximg}></img>
                                                            </div>
                                                            <div style={{ textAlign: "start", paddingLeft: "40px" }}>
                                                                <h3 style={{ color: "black" }}>UI/UX Designing</h3>
                                                                <div style={{ width: "90%" }}>
                                                                    <p style={{ fontSize: "16px", fontWeight: "400", lineHeight: "25px", color: "#5D5D5D" }}>We design stunning, user-centric apps with a focus on meeting your unique needs,
                                                                        enhancing user engagement for intuitive and delightful experiences.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='card-style1'>
                                                    <div className='card1'>
                                                        <div className='service-content-div1'>
                                                            <div className='main-content'>
                                                                <img style={{ height: "70px" }} src={appdev}></img>
                                                            </div>
                                                            <div style={{ textAlign: "start", paddingLeft: "40px" }}>
                                                                <h3>App Development</h3>
                                                                <div style={{ width: "90%" }}>
                                                                    <p style={{ fontSize: "16px", fontWeight: "400", lineHeight: "25px", color: "#5D5D5D" }}>Developing tailored mobile applications to meet your business needs, focusing on functionality,
                                                                        scalability, and delivering smooth user experiences.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='services-content1'>
                                                <div className='card-style1'>
                                                    <div className='card1'>
                                                        <div className='service-content-divbottom'>
                                                            <div className='main-content'>
                                                                <img style={{ height: "70px" }} src={digimarketingimg}></img>
                                                            </div>
                                                            <div style={{ textAlign: "start", paddingLeft: "40px" }}>
                                                                <h2>Digital Marketing</h2>
                                                                <div style={{ width: "90%" }}>
                                                                    <p style={{ fontSize: "16px", fontWeight: "400", lineHeight: "25px", color: "#5D5D5D" }}>Scaling app’s reach using Automotive Campaigns optimized by Ad-Ops experts.
                                                                        Get more User engagement and Installs on your application with Google/Fb Ads.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='card-style3'>
                                                    <div className='card'>
                                                        <div className='service-content-div1bottom'>
                                                            <div className='main-content'>
                                                                <img style={{ height: "70px" }} src={appmonetizationimg}></img>
                                                            </div>
                                                            <div style={{ textAlign: "start", paddingLeft: "40px" }}>
                                                                <h2>App Monetization</h2>
                                                                <div style={{ width: "80%" }}>
                                                                    <p style={{ fontSize: "16px", fontWeight: "400", lineHeight: "25px", color: "#5D5D5D" }}>Increasing your app’s ARPDAU with ROI-Driven Technologies,
                                                                        In-app Purchase Sales(IAP) and Get access to Premium DSPs and Ad Networks.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='blur-image-service-bottom'>
                                                <img src={blurimage}></img>
                                            </div>

                                        </div>

                                        {/* ==================Why Choose Us container tab==================== */}
                                        <div className='main-chooseus-container'>
                                            <div style={{ textAlign: "start", marginLeft: "13%" }}>
                                                <img style={{ height: "50px" }} src={chooseusname}></img>
                                            </div>
                                            <div className='card-style-counter'>
                                                <div className='card-counter'>
                                                    <div className='counter-wrapper'>
                                                        <div className='counter-div'>
                                                            <div className='numbers' style={{ marginLeft: "0px", paddingLeft: "0px" }}>
                                                                <Counter number={6} title="Impression Served" mainSuffix="B" additionalSuffix="+" additionalSuffixColor="red" />
                                                            </div>
                                                        </div>
                                                        <div className='counter-div'>
                                                            <div className='numbers'>
                                                                <Counter number={25} title="Official Partners" mainSuffix="" additionalSuffix="+" />
                                                            </div>                    </div>
                                                        <div className='counter-div'>
                                                            <div className='numbers'>
                                                                <Counter number={4000} title="App + Web Inventries" mainSuffix="" additionalSuffix="+" />
                                                            </div>                    </div>
                                                        <div className='counter-div'>
                                                            <div className='numbers'>
                                                                <Counter number={3} title="AD Spend" mainSuffix="M$" additionalSuffix="+" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='blur-image-chooseus'>
                                                <img src={blurimage}></img>
                                            </div>

                                            {/* =========================================================== */}
                                            <div>

                                                <div className='elements' style={{ textAlign: "end", marginRight: "5%", marginTop: "5%" }}>
                                                    <img style={{ height: "80px" }} src={circleele}></img>
                                                </div>
                                                <div className='chooseus-row'>
                                                    <div className='chooseus-col1'>
                                                        <div style={{ display: "flex", marginLeft: "7%" }}>
                                                            <span style={{ fontSize: "26px", color: "skyblue", fontWeight: "bold", }}>01</span>
                                                            <h1 style={{ marginLeft: "30px", fontSize: "26px", marginTop: "2px" }}> Google Partner</h1>
                                                        </div>
                                                        <div className='partner-content'>
                                                            <p>
                                                                Certified expertise for effective Google advertising strategies,
                                                                boosting your online presence and results.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='chooseus-col'>
                                                        <img src={gpartner}></img>
                                                    </div>
                                                </div>
                                                <div className='chooseus-rowsecond'>
                                                    <div className='chooseus-colsecond'>
                                                        <img src={ads}></img>
                                                    </div>
                                                    <div className='chooseus-col1'>
                                                        <div style={{ display: "flex", marginLeft: "7%" }}>
                                                            <span style={{ fontSize: "26px", color: "skyblue", fontWeight: "bold", marginTop: "10px" }}>02</span>
                                                            <h1 style={{ marginLeft: "10px", fontSize: "26px", marginTop: "10px" }}> Ad Exchange partner</h1>
                                                        </div>
                                                        <div className='partner-content'>
                                                            <p>
                                                                Unlock premium ad opportunities,
                                                                enhancing your revenue and reach through strategic partnerships.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='chooseus-rowsecond'>
                                                    <div className='chooseus-col1'>
                                                        <div style={{ display: "flex", marginLeft: "7%" }}>
                                                            <span style={{ fontSize: "26px", color: "skyblue", fontWeight: "bold", marginTop: "10px" }}>03</span>
                                                            <h1 style={{ marginLeft: "30px", fontSize: "26px", marginTop: "10px" }}>Payment Security</h1>
                                                        </div>
                                                        <div className='partner-content'>
                                                            <p>
                                                                Trustworthy transactions safeguard your financial interests,
                                                                ensuring a secure and reliable partnership.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='chooseus-col'>
                                                        <img src={payment}></img>
                                                    </div>
                                                </div>

                                                <div className='chooseus-rowsecond'>
                                                    <div className='chooseus-colsecond'>
                                                        <img src={consultation}></img>
                                                    </div>

                                                    <div className='chooseus-col1'>
                                                        <div style={{ display: "flex", marginLeft: "7%" }}>
                                                            <span style={{ fontSize: "26px", color: "skyblue", fontWeight: "bold", marginTop: "10px" }}>04</span>
                                                            <h1 style={{ marginLeft: "25px", fontSize: "26px", marginTop: "10px" }}>Expert Consultation</h1>
                                                        </div>
                                                        <div className='partner-content'>
                                                            <p>
                                                                Benefit from tailored guidance,
                                                                leveraging our industry insights for your success.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='blur-image-service-bottom'>
                                                    <img src={blurimage}></img>
                                                </div>
                                                <div className='chooseus-rowsecond'>
                                                    <div className='chooseus-col1'>
                                                        <div style={{ display: "flex", marginLeft: "7%" }}>
                                                            <span style={{ fontSize: "26px", color: "skyblue", fontWeight: "bold", marginTop: "10px" }}>05</span>
                                                            <h1 style={{ marginLeft: "0px", fontSize: "26px", marginTop: "10px" }}> 3x Return On Investment</h1>
                                                        </div>
                                                        <div className='partner-content'>
                                                            <p>
                                                                Achieve impressive returns, tripling your investments
                                                                through our proven strategies and campaigns.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='chooseus-col'>
                                                        <img src={investment}></img>
                                                    </div>
                                                </div>
                                                <div className='chooseus-rowsecond'>
                                                    <div className='chooseus-colrecord'>
                                                        <img src={record}></img>
                                                    </div>
                                                    <div className='chooseus-col1'>
                                                        <div style={{ display: "flex", marginLeft: "7%" }}>
                                                            <span style={{ fontSize: "26px", color: "skyblue", fontWeight: "bold", marginTop: "10px" }}>06</span>
                                                            <h1 style={{ marginLeft: "30px", fontSize: "26px", marginTop: "10px" }}>Proven Track Record</h1>
                                                        </div>
                                                        <div className='partner-content'>
                                                            <p>
                                                                Our successful history speaks for itself,
                                                                providing assurance of our commitment to excellence.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='elements' style={{ textAlign: "end", marginRight: "13%" }}>
                                                    <img style={{ height: "80px" }} src={searchele}></img>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ===========================Job opening Start tab======================== */}
                                        <div className='main-jobopening-container'>
                                            <div style={{ marginTop: "2%" }}>
                                                <img src={jobopeningname}></img>
                                            </div>
                                            <div className='elements' style={{ textAlign: "start", marginLeft: "15%", marginTop: "-2%" }}>
                                                <img src={bagele}></img>
                                            </div>

                                            <div className='job-row'>
                                                <div className='job-opening-row'>


                                                    <Slider  {...jobsettings}>
                                                        {
                                                            jobOpeningApiData && (
                                                                jobOpeningApiData.map((slide, index) => (
                                                                    <div key={index} className='jobopening-col' style={{ marginLeft: "30px" }}>

                                                                        <img src={jobopeningicon} alt="" />

                                                                        <h2 style={{ fontSize: "20px" }}>{slide.application_domain}</h2>
                                                                        <h3 style={{ lineHeight: "25px" }}>{slide.job_time} | {slide.job_exp}</h3>
                                                                        <h3>Job Vacancy : {slide.job_vacancy}</h3>
                                                                        <div className='submit-btn'>
                                                                            {slide.job_vacancy === "0" ? (
                                                                                <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                                            ) :
                                                                                (
                                                                                    <Button className='submit'
                                                                                        onClick={() => handleModalOpen(1, slide.application_domain)}
                                                                                    >Apply Now</Button>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )))}
                                                    </Slider>

                                                    {/* <Col className='jobopening-col'>
                                                    <img src={jobopeningicon} alt="" />
                                                    <h2 style={{ fontSize: "20px" }}>{android.application_domain}</h2>
                                                    <h3 style={{ lineHeight: "25px" }}>{android.job_time} | {android.job_exp}</h3>
                                                    <h3>Job Vacancy : {android.job_vacancy}</h3>
                                                    <div className='submit-btn'>
                                                        {android.job_vacancy === "0" ? (
                                                            <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                        ) :
                                                            (
                                                                <Button className='submit'
                                                                    onClick={() => handleModalOpen(2, android.application_domain)}
                                                                >Apply Now</Button>
                                                            )
                                                        }
                                                    </div>
                                                </Col>
                                                <Col className='jobopening-col'>
                                                    <img src={jobopeningicon} alt="" />
                                                    <h2 style={{ fontSize: "20px" }}>{flutter.application_domain}</h2>
                                                    <h3 style={{ lineHeight: "25px" }}>{flutter.job_time} | {flutter.job_exp}</h3>
                                                    <h3>Job Vacancy : {flutter.job_vacancy}</h3>
                                                    <div className='submit-btn'>
                                                        {flutter.job_vacancy === "0" ? (
                                                            <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                        ) :
                                                            (
                                                                <Button className='submit'
                                                                    onClick={() => handleModalOpen(3, flutter.application_domain)}
                                                                >Apply Now</Button>
                                                            )
                                                        }
                                                    </div>
                                                </Col>
                                                <Col className='jobopening-col'>
                                                    <img src={jobopeningicon} alt="" />
                                                    <h2 style={{ fontSize: "20px" }}>{appTester.application_domain}</h2>
                                                    <h3 style={{ lineHeight: "25px" }}>{appTester.job_time} | {appTester.job_exp}</h3>
                                                    <h3>Job Vacancy : {appTester.job_vacancy}</h3>
                                                    <div className='submit-btn'>
                                                        {appTester.job_vacancy === "0" ? (
                                                            <Button disabled className='submit' title='No Vacancy'>Apply Now</Button>
                                                        ) :
                                                            (
                                                                <Button className='submit'
                                                                    onClick={() => handleModalOpen(4, appTester.application_domain)}
                                                                >Apply Now</Button>
                                                            )
                                                        }
                                                    </div>
                                                </Col> */}
                                                </div>
                                            </div>
                                        </div>


                                        {/* ===========================Our Gallery Start tab========================= */}

                                        <div className='main-ourgallery-container'>
                                            <div style={{ textAlign: "start", marginLeft: "13%" }}>
                                                <img style={{ height: "50px" }} src={gallaryname}></img>
                                            </div>

                                            <div className="wrapper">
                                                <div className="center-slider">
                                                    <Slider  {...settings}>
                                                        {
                                                            eventImages && (


                                                                eventImages.map((slide, index) => (
                                                                    <div key={index}>
                                                                        {slide && (
                                                                            <img style={{ width: "100%" }} src={slide.event_cover_image}></img>
                                                                        )}
                                                                    </div>
                                                                ))
                                                            )
                                                        }

                                                    </Slider>
                                                    <div className='festivetext' style={{ display: "flex", justifyContent: 'center' }}>
                                                        <img style={{ padding: "10px", marginTop: "7px" }} src={line}></img>
                                                        <h2>
                                                            IDE Events
                                                        </h2>
                                                        <img style={{ padding: "10px", marginTop: "7px" }} src={line}></img>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>


                                </div>

                                <NewFooter />

                                {/* =============jobopening form modal start ============== */}

                                {/* Modal Form  */}
                                <Modal
                                    title="Application Form"
                                    footer={null}
                                    open={isModalOpen}
                                    onCancel={handleCancel}
                                >

                                    {/* <Form className='candidateForm' id='candidateForm' onSubmitCapture={handleCandidateForm}> */}
                                    <Form

                                        className='candidateForm' id='candidateForm' onFinish={handleCandidateForm}
                                        form={form}
                                    >

                                        {/* name */}
                                        <Form.Item
                                            name="name"
                                            rules={[
                                                { required: true, message: 'Please enter a name' },
                                                { min: 3, message: 'Name must be at least 3 characters' },
                                            ]}
                                            style={{ margin: '20px 5px' }}
                                        >
                                            <Input placeholder="Enter Your Name*"
                                                onChange={(e) => { setCandName(e.target.value) }}
                                            />
                                        </Form.Item>

                                        {/* Email  */}
                                        <Form.Item

                                            rules={[{ required: true, type: 'email', message: 'Please valid E-mail!' }]}
                                            style={{ margin: '20px 5px' }}
                                            name={"email"}
                                        >
                                            <Input
                                                placeholder="Enter Your Email*"
                                                onChange={(e) => { setCandEmail(e.target.value) }}
                                            />
                                        </Form.Item>

                                        {/* contact */}
                                        <Form.Item
                                            name="contact number"
                                            // rules={[{ required: true ,type : 'password' , message: 'Please enter password!' }]}
                                            rules={[
                                                { required: true, message: 'Please enter a Contact Number' },
                                                { min: 10, message: 'Experience must be at least 10 digits' },
                                            ]}
                                            style={{ margin: '20px 5px' }}
                                        >
                                            <Input placeholder="Enter Your Contact Number*"
                                                onChange={(e) => { setCandContact(e.target.value) }}
                                            />
                                        </Form.Item>

                                        {/* Experience  */}
                                        <Form.Item
                                            name="experience"
                                            // rules={[{ required: true ,type : 'password' , message: 'Please enter password!' }]}
                                            rules={[
                                                { required: true, message: 'Please enter a Experience' },
                                                { message: 'Experience required' },
                                            ]}
                                            style={{ margin: '20px 5px' }}
                                        >
                                            <Input placeholder="Enter Your Experience*"
                                                onChange={(e) => { setCandExp(e.target.value) }}
                                            />
                                        </Form.Item>


                                        {/* Designation  */}
                                        <Form.Item
                                            name="Designation"
                                            // rules={[{ required: true ,type : 'password' , message: 'Please enter password!' }]}
                                            rules={[
                                                { required: true, message: 'Please enter a Designation' },
                                                { message: 'Designation required' },
                                            ]}
                                            style={{ margin: '20px 5px' }}
                                        >
                                            <Input placeholder="Enter Your Designation*"
                                                onChange={(e) => { setCandDesig(e.target.value) }}
                                            />
                                        </Form.Item>

                                        <div style={{ marginBottom: '30px', height: "111px", width: "95%", marginLeft: "13px", marginTop: "20px" }}>
                                            <Upload.Dragger
                                                name="file"
                                                multiple={false}
                                                fileList={fileList}
                                                onChange={({ fileList }) => setFileList(fileList)}
                                                onRemove={(file) => {
                                                    const newFileList = fileList.filter((item) => item.uid !== file.uid);
                                                    setFileList(newFileList);
                                                }}
                                                accept='application/pdf'

                                            >
                                                <div style={{ display: "flex", marginTop: "10px" }}>
                                                    <p className="ant-upload-drag-icon" >
                                                        < InboxOutlined />
                                                    </p>
                                                    <p style={{ marginTop: "10px" }} className="ant-upload-text">Click or drag resume to this area to upload</p>

                                                </div>

                                            </Upload.Dragger>
                                        </div>
                                        <div style={{ textAlign: "center" }}>

                                            <button
                                                style={
                                                    { width: "40%", height: "45px", margin: "0px auto", padding: "0px", borderRadius: "8px", marginTop: "5px", borderColor: "gray", backgroundColor: "#0090E0", color: "white", fontSize: 18, cursor: "pointer", textAlign: "center" }
                                                }
                                                className='btn-pill'
                                                type="submit"
                                                disabled={isLoading}
                                            >
                                                {
                                                    isLoading ?
                                                        <p>Submitting</p>
                                                        :

                                                        <p style={{ marginTop: "10px" }}>Apply Now</p>
                                                }
                                            </button>
                                        </div>

                                    </Form>


                                </Modal>

                            </div >


                        </div >

                    )

            }
        </>
    )
}

export default Home
