import React from "react";
// import "./cardslide.css";
import "./Cardslide.css"
import img1 from "./images/Copy IDE Website/Group 37.png";
import img2 from "./images/Copy IDE Website/Group 39.png";
// import img3 from "./images/Copy IDE Website/Group 40.png";
// import img4 from "./images/Copy IDE Website/Mask group-1.png";
// import img5 from "./images/Copy IDE Website/Mask group-2.png";
// import img6 from "./images/Copy IDE Website/Mask group-3.png";
import img7 from "./images/Copy IDE Website/mopub.png";
import img8 from "./images/Copy IDE Website/WhatsApp Image 2023-01-03 at 11.32-1.png";
import img9 from "./images/Copy IDE Website/WhatsApp Image 2023-01-03 at 11.32-2.png";
import img10 from "./images/Copy IDE Website/WhatsApp Image 2023-01-03 at 11.32-3.png";
import img11 from "./images/Copy IDE Website/WhatsApp Image 2023-01-03 at 11.32-4.png";
import img12 from "./images/Copy IDE Website/WhatsApp Image 2023-01-03 at 11.32.png";

const arry1 = [
    img1,
    img2,
    // img3,
    // img4,
    // img5,
    // img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
];
const Cardslide = () => {
    return (
        <div>
            <div className="Footer-corosul">

                <div className="Footer-slider">
                    <div className="Footer-slidershadow">

                    </div>
                    <div className="Footer-slide-track">
                        {arry1.map((item, index) => (
                            <div className="Footer-slide" key={index}>
                                <img src={item} alt="img" />
                            </div>
                        ))}
                    </div>
                    <div className="Footer-slide-track footerphone">
                        {arry1.map((item, index) => (
                            <div className="Footer-slide" key={index}>
                                <img src={item} alt="img" />
                            </div>
                        ))}
                    </div>
                    <div className="Footer-slide-track footerphone">
                        {arry1.map((item, index) => (
                            <div className="Footer-slide" key={index}>
                                <img src={item} alt="img" />
                            </div>
                        ))}
                    </div>



                </div>


                {/* <div className="Footer-slider2">
  <div className="Footer-slidershadow">

  </div>
  <div className="Footer-slide-track2">
    {arry1.map((item, index) => (
      <div className="Footer-slide" key={index}>
        <img src={item} alt="img" />
      </div>
    ))}
  </div>

</div> */}
            </div>
        </div>
    )
}

export default Cardslide
