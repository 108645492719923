import React, { useEffect, useState } from 'react'
import "../services/Service.css"
import NavigationBar from "../../component/NavigationBar"
import bagele from "../../assets/images/elements/bag-ele.svg"
import searchele from "../../assets/images/elements/search-ele.svg"
import levelupele from "../../assets/images/elements/level-ele.svg"
import rectele from "../../assets/images/elements/Rectangleele.svg"
import rocketele from "../../assets/images/elements/rocketele.svg"
import circleele from "../../assets/images/elements/circle-ele.svg"
import blurimage from "../../assets/images/homepage-images/blureffect.svg"
import bulbele from "../../assets/images/elements/bulb.svg"
import servicename from "../../assets/images/services/Our Services.svg"
import uiuximg from "../../assets/images/services/uiux.svg"
import appdev from "../../assets/images/services/appdevelop.svg"
import digimarketingimg from "../../assets/images/services/digimarketing.svg"
import appmonetizationimg from "../../assets/images/services/appmonetization.svg"
import serviceshowimage from "../../assets/images/about-images/aboutimage.webp"
import uiuxbannerimg from "../../assets/images/services/uiux.png"
import appdevbannerimg from "../../assets/images/services/appdev.webp"
import digitalmarbannerimg from "../../assets/images/services/digitalmar.webp"
import appmonetizationbannerimg from "../../assets/images/services/appmonet.webp"
import NewFooter from '../footer/NewFooter'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const CustomLoadingAnimation = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '300px' }}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      <p style={{ marginTop: '10px' }}>Loading...</p>
    </div>
  )
}

const Service = () => {
  const [loadingAnimation, setLoadingAnimation] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts or updates

    setLoadingAnimation(true)
    setTimeout(() => {
        setLoadingAnimation(false)
    }, 100)
  }, []);

  return (
    <>
      {
        loadingAnimation ?
          (
            <CustomLoadingAnimation />
          )
          :
          (
            <div className='container'>
              <div style={{ position: "fixed", zIndex: 999, marginTop: "-5px" }}>
                <NavigationBar />
              </div>
              <div>
                <div className='main-service-container-service'>
                  <div className='imagediv1'>
                    <div id='elements' className='eleimage1'>
                      <img src={bulbele}></img>
                    </div>
                    <div id='elements' className='eleimage2'>
                      <img src={bagele}></img>
                    </div>
                  </div>
                  <div className='blur-image-service'>
                    <img src={blurimage}></img>
                  </div>
                  <div className='servicenameservice'>
                    <div id='elements' className='eleimage3'>
                      <img src={rocketele}></img>
                    </div>
                    <div className='servicenameimage'>
                      <img src={servicename}></img>
                    </div>
                    <div className='eleimage4'>
                      <img id='elements' src={circleele}></img>
                    </div>
                  </div>

                  <div className='imagediv2'>
                    <div className='eleimage1'>
                      <img id='elements' src={searchele}></img>
                    </div>
                    <div className='eleimage2'>
                      <img id='elements' src={levelupele}></img>
                    </div>
                  </div>
                </div>
                {/* ========================content start ================== */}
                <div className='Services-content-start'>
                  <div className='contentservicemain1'>
                    <div className='serviceimage1'>
                      <img src={uiuxbannerimg}></img>
                    </div>
                    <div className='servicecontent1'>
                      <div className='main-content'>
                        <img src={uiuximg}></img>
                      </div>
                      <div style={{ textAlign: "start", paddingLeft: "50px" }}>
                        <h2 style={{ color: "black" }}>UI/UX Designing</h2>
                        <div className='parastyle'  style={{ width: "90%" }}>
                          <p style={{ fontSize: "24px", fontWeight: "400", lineHeight: "30px", color: "#5D5D5D" }}>We design stunning, user-centric apps with a focus on meeting your unique needs,
                            enhancing user engagement for intuitive and delightful experiences.</p>
                        </div>
                      </div>
                    </div>

                  </div>


                  <div className='contentservicemain1'>

                    <div className='servicecontent2'>
                      <div className='main-content'>
                        <img src={appdev}></img>
                      </div>
                      <div style={{ textAlign: "start", paddingLeft: "50px" }}>
                        <h2>App Development</h2>
                        <div className='parastyle' style={{ width: "90%" }}>
                          <p style={{ fontSize: "24px", fontWeight: "400", lineHeight: "30px", color: "#5D5D5D" }}>Developing tailored mobile applications to meet your business needs, focusing on functionality,
                            scalability, and delivering smooth user experiences.</p>
                        </div>
                      </div>
                    </div>
                    <div className='serviceimage1'>
                      <img src={appdevbannerimg}></img>
                    </div>

                  </div>


                  <div className='contentservicemain1'>

                    <div className='serviceimage1'>
                      <img src={digitalmarbannerimg}></img>
                    </div>
                    <div className='servicecontent1'>
                      <div className='main-content'>
                        <img src={digimarketingimg}></img>
                      </div>
                      <div style={{ textAlign: "start", paddingLeft: "50px" }}>
                        <h2>Digital Marketing</h2>
                        <div className='parastyle' style={{ width: "90%" }}>
                          <p style={{ fontSize: "24px", fontWeight: "400", lineHeight: "30px", color: "#5D5D5D" }}>Scaling app’s reach using Automotive Campaigns optimized by Ad-Ops experts.
                            Get more User engagement and Installs on your application with Google/Fb Ads.</p>
                        </div>
                      </div>
                    </div>

                  </div>


                  <div className='contentservicemain1'>

                    <div className='servicecontent2'>
                      <div className='main-content'>
                        <img src={appmonetizationimg}></img>
                      </div>
                      <div style={{ textAlign: "start", paddingLeft: "50px" }}>
                        <h2>App Monetization</h2>
                        <div className='parastyle' style={{ width: "80%" }}>
                          <p style={{ fontSize: "24px", fontWeight: "400", lineHeight: "30px", color: "#5D5D5D" }}>Increasing your app’s ARPDAU with ROI-Driven Technologies,
                            In-app Purchase Sales(IAP) and Get access to Premium DSPs and Ad Networks.</p>
                        </div>
                      </div>
                    </div>
                    <div className='serviceimage1'>
                      <img src={appmonetizationbannerimg}></img>
                    </div>

                  </div>
                </div>


                {/* ===================================**********************************============================= */}
                {/* ==================Mobile Screen Start ====================== */}
                <div className='main-service-container-service-mob'>
                  <div className='imagediv1'>
                    <div id='elements' className='eleimage1'>
                      <img src={bulbele}></img>
                    </div>
                    <div id='elements' className='eleimage2'>
                      <img src={bagele}></img>
                    </div>
                  </div>

                  <div className='blur-image-service'>
                    <img src={blurimage}></img>
                  </div>
                  <div className='servicenameservice'>
                    <div id='elements' className='eleimage3'>
                      <img src={rocketele}></img>
                    </div>
                    <div className='servicenameimage'>
                      <img src={servicename}></img>
                    </div>
                    <div className='eleimage4'>
                      <img id='elements' src={circleele}></img>
                    </div>
                  </div>

                  <div className='imagediv2'>
                    <div className='eleimage1'>
                      <img id='elements' src={searchele}></img>
                    </div>
                    <div className='eleimage2'>
                      <img id='elements' src={levelupele}></img>
                    </div>
                  </div>

                </div>

                <div className='Services-content-start-mob'>
                  <div>
                    <div className='serviceimagemobdiv'>
                      <img src={uiuxbannerimg}></img>
                    </div>
                    <div>
                      <div className='main-content'>
                        <img src={uiuximg}></img>
                      </div>
                      <div className='maincontentmob' style={{ textAlign: "start", paddingLeft: "50px" }}>
                        <h2 style={{ color: "black" }}>UI/UX Designing</h2>
                        <div className='contenttextmob' style={{ width: "90%" }}>
                          <p style={{ fontSize: "22px", fontWeight: "400", lineHeight: "30px", color: "#5D5D5D" }}>We design stunning, user-centric apps with a focus on meeting your unique needs,
                            enhancing user engagement for intuitive and delightful experiences.</p>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div>
                    <div className='serviceimagemobdiv'>
                      <img src={appdevbannerimg}></img>
                    </div>
                    <div>
                      <div className='main-content'>
                        <img src={appdev}></img>
                      </div>
                      <div className='maincontentmob' style={{ textAlign: "start", paddingLeft: "50px" }}>
                        <h2 style={{ color: "black" }}>App Development</h2>
                        <div className='contenttextmob' style={{ width: "90%" }}>
                          <p style={{ fontSize: "22px", fontWeight: "400", lineHeight: "30px", color: "#5D5D5D" }}>Developing tailored mobile applications to meet your business needs, focusing on functionality,
                            scalability, and delivering smooth user experiences.</p>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div>
                    <div className='serviceimagemobdiv'>
                      <img src={digitalmarbannerimg}></img>
                    </div>
                    <div>
                      <div className='main-content'>
                        <img src={digimarketingimg}></img>
                      </div>
                      <div className='maincontentmob' style={{ textAlign: "start", paddingLeft: "50px" }}>
                        <h2 style={{ color: "black" }}>Digital Marketing</h2>
                        <div className='contenttextmob' style={{ width: "90%" }}>
                          <p style={{ fontSize: "22px", fontWeight: "400", lineHeight: "30px", color: "#5D5D5D" }}>We design stunning, user-centric apps with a focus on meeting your unique needs,
                            enhancing user engagement for intuitive and delightful experiences.</p>
                        </div>
                      </div>
                    </div>

                  </div>


                  <div>
                    <div className='serviceimagemobdiv'>
                      <img src={appmonetizationbannerimg}></img>
                    </div>
                    <div>
                      <div className='main-content'>
                        <img src={appmonetizationimg}></img>
                      </div>
                      <div className='maincontentmob' style={{ textAlign: "start", paddingLeft: "50px" }}>
                        <h2 style={{ color: "black" }}>App Monetization</h2>
                        <div className='contenttextmob' style={{ width: "90%" }}>
                          <p style={{ fontSize: "22px", fontWeight: "400", lineHeight: "30px", color: "#5D5D5D" }}>Scaling app’s reach using Automotive Campaigns optimized by Ad-Ops experts.
                            Get more User engagement and Installs on your application with Google/Fb Ads.</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <NewFooter />

              </div>

            </div>
          )}
    </>
  )
}

export default Service
