import React, { useState, useEffect, } from 'react'
import "../about/About.css"
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import NavigationBar from "../../component/NavigationBar"
import bagele from "../../assets/images/elements/bag-ele.svg"
import searchele from "../../assets/images/elements/search-ele.svg"
import levelupele from "../../assets/images/elements/level-ele.svg"
import rectele from "../../assets/images/elements/Rectangleele.svg"
import aboutname from "../../assets/images/about-images/aboutname.svg"
import mission from "../../assets/images/about-images/mission.png"
import vision from "../../assets/images/about-images/vission.png"
import blurimage from "../../assets/images/homepage-images/blureffect.svg"
import jounrneyname from "../../assets/images/about-images/ourjourney.svg"
import circleele from "../../assets/images/elements/circle-ele.svg"
import journeyline from "../../assets/images/about-images/waveline.svg"
import pin1 from "../../assets/images/about-images/pin1.svg"
import pin2 from "../../assets/images/about-images/pin2.svg"
import pillarname from "../../assets/images/about-images/pillars.svg"
import Slider from "react-slick";
import NewFooter from '../footer/NewFooter'
import visionmissionname from "../../assets/images/about-images/missionvisionname.svg"
import journeymobpin1 from "../../assets/images/about-images/mobpin1.svg"
import journeymobpin2 from "../../assets/images/about-images/mobpin2.svg"
import journeytextbg1 from "../../assets/images/about-images/mobtextbg1.png"
import journeytextbg2 from "../../assets/images/about-images/mobtextbg2.png"
import journeytextbg3 from "../../assets/images/about-images/mobtextbg3.png"
import journeytextbg4 from "../../assets/images/about-images/mobtextbg4.png"
import { useGetAboutVideoQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";
import { useGetPillarsDataQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";

const CustomLoadingAnimation = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '300px' }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            <p style={{ marginTop: '10px' }}>Loading...</p>
        </div>
    )
}


const About = () => {
    const [divsVisible, setDivsVisible] = useState([false, false, false]);
    const [divsVisibleSecond, setDivsVisibleSecond] = useState([false, false, false]);
    const [divsVisibleThird, setDivsVisibleThird] = useState([false, false, false]);
    const [divsVisibleFourth, setDivsVisibleFourth] = useState([false, false, false]);
    const [loadingAnimation, setLoadingAnimation] = useState(true)
    const [videoUrl, setVideoUrl] = useState(null)
    const [pillars, setPillars] = useState(null)


    const { data: aboutVideoUrl, isLoading: isLoadingAboutVideoUrl, isSuccess: isSuccessAboutVideoUrl } = useGetAboutVideoQuery()
    const { data: pillarsData, isLoading: isLoadingPillarsData, isSuccess: isSuccessPillarsData } = useGetPillarsDataQuery()




    useEffect(() => {

        handleToFetchAboutData()
        handleToGetPillarsData()
    }, [aboutVideoUrl, pillarsData])

    const handleToFetchAboutData = () => {
        if (aboutVideoUrl && isSuccessAboutVideoUrl) {
            setVideoUrl(aboutVideoUrl.about_video);
        }
    }

    const handleToGetPillarsData = () => {
        if (pillarsData && isSuccessPillarsData) {
            setPillars(pillarsData)
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts or updates
        setLoadingAnimation(true)
        setTimeout(() => {
            setLoadingAnimation(false)
        }, 100)

    }, []);

    // Our journey animation 1
    useEffect(() => {
        const timeoutIds = [];
        const delays = [1000, 1500, 2000]; // Adjust delays as needed

        delays.forEach((delay, index) => {
            const timeoutId = setTimeout(() => {
                setDivsVisible(prevState => {
                    const newDivsVisible = [...prevState];
                    newDivsVisible[index] = true;
                    return newDivsVisible;
                });
            }, delay);
            timeoutIds.push(timeoutId);
        });

        return () => {
            timeoutIds.forEach(timeoutId => clearTimeout(timeoutId));
        };
    }, []);
    // Our journey animation 2
    useEffect(() => {
        const timeoutIds = [];
        const delays = [2500, 3000, 3500]; // Adjust delays as needed

        delays.forEach((delay, index) => {
            const timeoutId = setTimeout(() => {
                setDivsVisibleSecond(prevState => {
                    const newDivsVisibleSecond = [...prevState];
                    newDivsVisibleSecond[index] = true;
                    return newDivsVisibleSecond;
                });
            }, delay);
            timeoutIds.push(timeoutId);
        });

        return () => {
            timeoutIds.forEach(timeoutId => clearTimeout(timeoutId));
        };
    }, []);
    // Our journey animation 3
    useEffect(() => {
        const timeoutIds = [];
        const delays = [4000, 4500, 5000]; // Adjust delays as needed

        delays.forEach((delay, index) => {
            const timeoutId = setTimeout(() => {
                setDivsVisibleThird(prevState => {
                    const newDivsVisibleThird = [...prevState];
                    newDivsVisibleThird[index] = true;
                    return newDivsVisibleThird;
                });
            }, delay);
            timeoutIds.push(timeoutId);
        });

        return () => {
            timeoutIds.forEach(timeoutId => clearTimeout(timeoutId));
        };
    }, []);
    // Our journey animation 4
    useEffect(() => {

        const timeoutIds = [];
        const delays = [5500, 6000, 6500]; // Adjust delays as needed

        delays.forEach((delay, index) => {
            const timeoutId = setTimeout(() => {
                setDivsVisibleFourth(prevState => {
                    const newDivsVisibleFourth = [...prevState];
                    newDivsVisibleFourth[index] = true;
                    return newDivsVisibleFourth;
                });
            }, delay);
            timeoutIds.push(timeoutId);
        });

        return () => {
            timeoutIds.forEach(timeoutId => clearTimeout(timeoutId));
        };
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: true,
        initialSlide: 0,
        draggable: true,
        arrows: false,
        swipeToSlide: true,
        marginLeft: '20px',
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: true,
                    infinite: true,
                    draggable: true,
                    swipeToSlide: true,
                    dots: false,
                    marginLeft: '20px',
                }
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: true,
                    infinite: true,
                    draggable: true,
                    swipeToSlide: true,
                    dots: false,
                    marginLeft: '20px',
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: true,
                    infinite: true,
                    draggable: true,
                    swipeToSlide: true,
                    dots: false,
                    marginLeft: '20px',
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: true,
                    infinite: true,
                    draggable: true,
                    dots: false,
                    marginLeft: '20px',
                    swipeToSlide: true,

                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: true,
                    draggable: true,
                    initialSlide: 2,
                    swipeToSlide: true,

                }
            },
            {
                breakpoint: 630,
                settings: {
                    slidesToShow: 1,
                    draggable: true,
                    slidesToScroll: true,
                    marginLeft: '20px',
                    swipeToSlide: true,

                }
            },

        ]
    };



    return (
        <>

            {
                loadingAnimation ?
                    (
                        <CustomLoadingAnimation />
                    )
                    :
                    (
                        <div className='container'>
                            <div style={{ position: "fixed", zIndex: 999, marginTop: "-5px" }}>
                                <NavigationBar />
                            </div>

                            <div className='main-about-container-about'>
                                <div className='about-content-about'>
                                    <div className='content-aboutdiv'>
                                        <div className='about-info-about'>
                                            <div className='aboutnameabout' style={{ textAlign: "start" }}>
                                                <img style={{}} src={aboutname}></img>
                                            </div>
                                            <h2>We're a Google Partner agency, experts in ad operations, app development and  boosting your app's success
                                                by fine-tuning ARPU, ensuring every user contributes to
                                                your app's long-term revenue stream.
                                            </h2>
                                        </div>
                                        <div id='ele1' className='elements' style={{ marginTop: "-30px" }}>
                                            <img src={bagele}></img>
                                        </div>

                                        <div id='recteleup' className='elements' style={{ marginTop: "-20px", marginLeft: '-120px', zIndex: '-1' }}>
                                            <img src={rectele}></img>
                                        </div>
                                        <div className='about-image-about' style={{ zIndex: 1 }}>

                                            {/* <video src={videoUrl} autoPlay={true} muted={true} loop type="video/mov" className='videoabout' style={{ width: "100%", zIndex: 1 }} id="myVideo" /> */}
                                            <img src={videoUrl}  style={{ width: "100%", zIndex: 1, borderTopRightRadius:"100px", borderBottomLeftRadius:"100px" }} id="myVideo" />

                                        </div>

                                        <div id='rectele' className='elements' style={{ marginTop: "-20px", marginLeft: '-120px', zIndex: '-1' }}>
                                            <img src={rectele}></img>
                                        </div>
                                        <div id='levelid1' className='elements' style={{ marginLeft: "0px", marginTop: "250px" }}>
                                            <img src={levelupele}></img>

                                        </div>
                                    </div>

                                </div>
                                <div >
                                    <div id='rectid1' className='elements' style={{ marginTop: "-160px", marginLeft: '-80px', zIndex: '-1' }}>
                                        <img src={rectele}></img>
                                    </div>
                                    <div id='searchid1' className='elements' style={{ marginLeft: "390px", marginTop: "0px" }}>
                                        <img src={searchele}></img>
                                    </div>
                                </div>
                            </div>

                            {/* ===================vision-mission container============== */}

                            <div className='vision-mission-container'>
                                <div style={{ width: "20%" }}>
                                    <div className='blur-image-about'>
                                        <img src={blurimage}></img>
                                    </div>
                                </div>

                                <div style={{ width: "30%" }}>
                                    <div className='vision' >
                                        <div className='vision-img'>
                                            <img src={mission}></img>
                                        </div>
                                        <h2>Our Mission</h2>
                                        <div className='vision-text'>
                                            <p>Our Mission is to empower businesses digitally by providing
                                                covariant innovative technology solutions in every facet of business.</p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: "30%", marginTop: "100px" }}>
                                    <div className='mission'>
                                        <div className='mission-img'>
                                            <img src={vision}></img>
                                        </div>
                                        <h2>Our Vision</h2>
                                        <div className='mission-text'>
                                            <p>Our Vision is to become a Transformative agency that helps Mobile App businesses through data-driven
                                                marketing for accelerated advertising revenue growth.</p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: "20%" }}>
                                    <div className='blur-image-left'>
                                        <img src={blurimage}></img>
                                    </div>
                                </div>




                            </div>

                            {/* ===================vision-mission container mob============== */}

                            <div className='vision-mission-container-mob'>
                                <img src={visionmissionname}></img>

                                <div className='vission-missiondivmob'>
                                    <div className='missionmob'>
                                        <div className='vision' >
                                            <div className='vision-img'>
                                                <img src={mission}></img>
                                            </div>
                                            <h2>Our Mission</h2>
                                            <div className='vision-text'>
                                                <p>Our Mission is to empower businesses digitally by providing
                                                    covariant innovative technology solutions in every facet of business.</p>
                                            </div>
                                        </div>
                                        <div className='our-missionelemob'>
                                            <div id='bagelevision' className='elements' >
                                                <img src={bagele}></img>
                                            </div>

                                            <div className='blur-image-slider-mob'>
                                                <img src={blurimage}></img>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='visionmob'>
                                        <div className='our-missionelemob'>

                                        </div>
                                        <div className='vision' >
                                            <div className='vision-img'>
                                                <img src={vision}></img>
                                            </div>
                                            <h2>Our Vision</h2>
                                            <div className='vision-text'>
                                                <p>Our Vision is to become a Transformative agency that helps Mobile App businesses through data-driven
                                                    marketing for accelerated advertising revenue growth.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div id='levelidmission' className='elements' >
                                        <img src={levelupele}></img>
                                    </div>

                                    <div className='blur-image-vision-mob'>
                                        <img src={blurimage}></img>
                                    </div>

                                </div>
                            </div>
                            {/* ==================Our Journey Container start===============*/}

                            <div className='journey-container'>
                                <div className='jounrneyname' style={{ textAlign: "center" }}>
                                    <img src={jounrneyname}></img>
                                </div>
                                <div className='elements' style={{ marginLeft: "70px", textAlign: "start", marginTop: "-30px" }}>
                                    <img src={levelupele}></img>
                                </div>
                                <div className='elements' style={{ textAlign: "end", marginTop: "-50px" }}>
                                    <img src={circleele}></img>
                                </div>

                                <div className='waveimage'>
                                    <img style={{ width: "100%" }} src={journeyline}></img>
                                </div>


                                <div className='horizontaldiv' style={{ display: "flex" }}>
                                    <div className='pin1'>
                                        <div className='main-pin' style={{ display: "flex", }}>
                                            <img className={divsVisible[0] ? "visible" : "hidden"} src={pin1}></img>
                                            <div className='pinside'>
                                                <p className={divsVisible[1] ? "visible" : "hidden"}>2020</p>
                                                <div className='downtext'>
                                                    {/* <img className={divsVisible[2] ? "visible" : "hidden"} src={pin1textimg}></img> */}
                                                    <div className={divsVisible[2] ? "visible" : "hidden"}>
                                                        <div className='pin-info-text'>
                                                            <p>Six rupees, six interns and a
                                                                humble beginning marks IDE’s foundation.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className='pin2'>
                                        <div className='main-pin' style={{ display: "flex" }}>
                                            <img className={divsVisibleSecond[0] ? "visible" : "hidden"} src={pin2}></img>
                                            <div className='pinside2'>
                                                <div className='downtext2'>
                                                    <div className={divsVisibleSecond[1] ? "visible" : "hidden"}>
                                                        <div className='pin-info-text1'>
                                                            <p>Google partnership and 300x growth
                                                                marked a year of resilience.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className={divsVisibleSecond[2] ? "visible" : "hidden"}>2021</p>
                                            </div>

                                        </div>

                                    </div>
                                    <div className='pin4'>
                                        <div className='main-pin' style={{ display: "flex", }}>
                                            <img className={divsVisibleThird[0] ? "visible" : "hidden"} src={pin1}></img>
                                            <div className='pinside'>
                                                <p className={divsVisibleThird[1] ? "visible" : "hidden"}>2022</p>
                                                <div className='downtext'>
                                                    <div className={divsVisibleThird[2] ? "visible" : "hidden"}>
                                                        <div className='pin-info-text'>
                                                            <p>Rs. 10 crore turnover, IDE transformed into a family.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className='pin3'>
                                        <div className='main-pin' style={{ display: "flex" }}>
                                            <img className={divsVisibleFourth[0] ? "visible" : "hidden"} src={pin2}></img>
                                            <div className='pinside3'>
                                                <div className='downtext3'>
                                                    <div className={divsVisibleFourth[1] ? "visible" : "hidden"}>
                                                        <div className='pin-info-text3'>
                                                            <p>IDE thrives with app developers, marketers, and sister companies.
                                                                The journey from six rupees to a sky full of stars.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className={divsVisibleFourth[2] ? "visible" : "hidden"}>2023</p>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>



                            <div id='bagelejourney' className='elements' style={{ marginLeft: "27%", textAlign: "start", marginTop: "100px" }}>
                                <img src={bagele}></img>
                            </div>


                            {/* ==================Our Journey mobile Container start===============*/}
                            <div className='journey-container-mob'>
                                <div style={{ textAlign: "center" }}>
                                    <img style={{ height: "60px" }} src={jounrneyname}></img>
                                </div>

                                <div className="journey-main-mob">
                                    <div className='journey-mob-pin1'>
                                        <div style={{ textAlign: "start" }}>
                                            <img className={divsVisible[0] ? "visible" : "hidden"} style={{ height: "20px", width: "70%" }} src={journeymobpin1}></img>
                                        </div>
                                        <div className='journeytextbg1'>
                                            <div>
                                                <img className={divsVisible[1] ? "visible" : "hidden"} src={journeytextbg1}></img>
                                            </div>
                                            <div>
                                                <h3 className={divsVisible[2] ? "visible" : "hidden"}>2020</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='journey-mob-pin2'>
                                        <div style={{ textAlign: "end" }}>
                                            <img className={divsVisibleSecond[0] ? "visible" : "hidden"} style={{ height: "20px", width: "70%" }} src={journeymobpin2}></img>
                                        </div>
                                        <div className='journeytextbg2'>
                                            <div>
                                                <h3 className={divsVisibleSecond[1] ? "visible" : "hidden"}>2021</h3>
                                            </div>
                                            <div>
                                                <img className={divsVisibleSecond[2] ? "visible" : "hidden"} src={journeytextbg2}></img>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='journey-mob-pin1'>
                                        <div style={{ textAlign: "start" }}>
                                            <img className={divsVisibleThird[0] ? "visible" : "hidden"} style={{ height: "20px", width: "70%" }} src={journeymobpin1}></img>
                                        </div>
                                        <div className='journeytextbg1'>
                                            <div>
                                                <img className={divsVisibleThird[1] ? "visible" : "hidden"} src={journeytextbg3}></img>
                                            </div>
                                            <div>
                                                <h3 className={divsVisibleThird[2] ? "visible" : "hidden"}>2022</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='journey-mob-pin2'>
                                        <div style={{ textAlign: "end" }}>
                                            <img className={divsVisibleFourth[0] ? "visible" : "hidden"} style={{ height: "20px", width: "70%" }} src={journeymobpin2}></img>
                                        </div>
                                        <div className='journeytextbg2'>
                                            <div>
                                                <h3 className={divsVisibleFourth[1] ? "visible" : "hidden"}>2023</h3>
                                            </div>
                                            <div>
                                                <img className={divsVisibleFourth[2] ? "visible" : "hidden"} src={journeytextbg4}></img>
                                            </div>
                                        </div>
                                        <div style={{ height: "10px" }}>

                                        </div>
                                    </div>
                                    {/* <img src={journeymobwaveline}></img> */}
                                </div>
                            </div>
                            {/* ===========================Pillars container start ==================== */}
                            <div className='pillars-container'>
                                <div className='pillarheading' style={{ textAlign: "center" }}>
                                    <img src={pillarname}></img>
                                </div>
                                <div className="slider-container-about">

                                    <Slider {...settings}>
                                        {
                                            pillars && (

                                                pillars.map((slide, index) => (

                                                    <div key={index} style={{ margin: '0px 10px' }} className='slide'  >
                                                        <div className='slide-image'>
                                                            {slide && (
                                                                <img src={slide.pillers_image} ></img>
                                                            )}
                                                        </div>
                                                        {slide && (
                                                            <h1>{slide.piller_name}</h1>
                                                        )}
                                                        {slide && (
                                                            <h3>{slide.piller_designation}</h3>
                                                        )}
                                                    </div>

                                                    // <p>{slide.piller_name}</p>

                                                ))
                                            )
                                        }



                                    </Slider>
                                </div>

                            </div>


                            {/* ===========================Footer Container Start ================= */}

                            <NewFooter />

                        </div>
                    )
            }
        </>
    )
}

export default About
